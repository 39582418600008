import { components, IndicatorComponentType } from "react-select";

import { Icon } from "components";

import { IsMulti, OptionType } from "../../types";

export const DropdownIndicator: IndicatorComponentType<OptionType, IsMulti> = (props) => (
  <components.DropdownIndicator {...props}>
    <Icon icon="chevron-down" className="dropdown-indicator-icon" />
  </components.DropdownIndicator>
);
