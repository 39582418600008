import { ReactComponent as ArrowRightIcon } from "assets/icons/arrow-right.svg";
import { ROUTES } from "config";
import { Redirect } from "react-router";
import { useTypedSelector } from "store";
import { selectOrgState } from "store/organization/selectors";

import { ContactContainer, ContactText, ContentContainer, EmailText, Heading, ReturnText, StatusIcon } from "./styles";
import { OrganizationStatusEnum } from "types";

const email = "info@clearpathnyc.org";

interface PendingProps {
  isPage?: boolean;
}

export const Pending: React.FC<PendingProps> = ({ isPage = false }) => {
  const { currentOrg } = useTypedSelector(selectOrgState);

  if (!currentOrg) {
    return <Redirect to={ROUTES.Home} />;
  }

  const isPending = currentOrg.status === undefined || currentOrg.status === OrganizationStatusEnum.PENDING;

  return (
    <ContentContainer>
      <StatusIcon icon={isPending ? (isPage ? "pending-clock" : "pending-check") : "close-alt"} />
      <Heading>{isPending ? "Your request is pending!" : "Your request was denied."}</Heading>
      <ReturnText to={ROUTES.Logout}>
        Return to Login Page <ArrowRightIcon />
      </ReturnText>
      <ContactContainer>
        <ContactText>Contact </ContactText>
        <EmailText href={`mailto:${email}`} target="_blank" rel="noopener noreferrer">
          {email}
        </EmailText>
      </ContactContainer>
    </ContentContainer>
  );
};

export default Pending;
