import { Icon } from "../icon";
import { DropdownMenu, DropdownGroup, DropdownItem } from "./styles";
import { ROUTES } from "config";
import { useLocation } from "react-router-dom";
import { DropdownProps } from "./types";
import { IconType } from "../icon";
import { useTypedSelector } from "store";
import { selectOrgState } from "store/organization/selectors";
import { OrganizationStatusEnum } from "types";

export const Dropdown = ({ isMobile, isOrganization }: DropdownProps) => {
  const location = useLocation();
  const pathname = location.pathname;
  const { currentOrg } = useTypedSelector(selectOrgState);

  const isPathname = (path: string) => {
    return pathname === path;
  };

  const userLinks = [
    {
      icon: "location",
      text: "Clearpath Map",
      link: ROUTES.Home,
    },
    {
      icon: "circles",
      text: "Application Tracker",
      link: ROUTES.Applications,
    },
    {
      icon: "user",
      text: "User Profile",
      link: ROUTES.UserProfile,
    },
    {
      icon: "logout",
      text: "Log Out",
      link: ROUTES.Logout,
    },
  ];

  const isAcceptedOrganization = Boolean(currentOrg?.status === OrganizationStatusEnum.ACCEPTED);

  const organizationLinks = isAcceptedOrganization
    ? [
        {
          icon: "circles",
          text: "Organization Dashboard",
          link: ROUTES.OrganizationHome,
        },
        {
          icon: "user",
          text: "Organization Profile",
          link: ROUTES.OrganizationProfile,
        },
        {
          icon: "logout",
          text: "Log Out",
          link: ROUTES.Logout,
        },
      ]
    : [
        {
          icon: "logout",
          text: "Log Out",
          link: ROUTES.Logout,
        },
      ];

  const dropdownLinks = isOrganization ? organizationLinks : userLinks;

  return (
    <DropdownMenu isMobile={isMobile} isOrganization={isOrganization}>
      <DropdownGroup>
        {dropdownLinks.map((dropdownLink) => (
          <DropdownItem to={dropdownLink.link} isLinkActive={isPathname(dropdownLink.link)} key={dropdownLink.text}>
            <Icon icon={dropdownLink.icon as IconType} className="dropdown-icon" />
            {dropdownLink.text}
          </DropdownItem>
        ))}
      </DropdownGroup>
    </DropdownMenu>
  );
};
