import { Header } from "./header";
import { MainLayoutProps } from "./types";
import { ContentContainer, MainContainer } from "./styles";

export const MainLayout = ({ children }: MainLayoutProps) => (
  <MainContainer>
    <Header />
    <ContentContainer>{children}</ContentContainer>
  </MainContainer>
);
