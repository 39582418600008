import ScrollLock from "react-scrolllock";

import { CardInfoBlock, CardHeading } from "components/support-card/styles";
import { CategoryContainer, ErrorText, LargeContainer, StyledForm } from "./styles";
import { useEffect, useState } from "react";
import { CardText } from "pages/main/home/styles";

import OutreachPreview from "../../../assets/images/reviewApplicantsPreview.png";
import ReviewApplicantPreview from "../../../assets/images/outreachPreview.png";
import { OrganizationCategoryDisplayProps } from "./types";
import { Formik } from "formik";
import { ViewPreferenceEnum } from "types";

export const OrganizationCategory = ({
  isModal,
  viewPreference,
  setViewPreference,
  setFormId,
  nextClick,
}: OrganizationCategoryDisplayProps) => {
  const [categoryError, setCategoryError] = useState(false);

  useEffect(() => {
    setFormId("category");
  }, [setFormId]);

  const showVariant = (container: string) => {
    return container === viewPreference ? "active" : "default";
  };

  return (
    <LargeContainer isModal={isModal} variant={"default"}>
      {categoryError && <ErrorText>Please select your organization's category</ErrorText>}

      <Formik
        initialValues={{ viewPreference }}
        onSubmit={() => {
          if (viewPreference === undefined) {
            setCategoryError(true);
            return;
          }

          setCategoryError(false);
          nextClick();
        }}
      >
        {({ handleSubmit }) => {
          return (
            <StyledForm
              id="category"
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <CategoryContainer
                isModal={isModal}
                variant={showVariant(ViewPreferenceEnum.OUTREACH)}
                onClick={() => setViewPreference(ViewPreferenceEnum.OUTREACH)}
              >
                <ScrollLock isActive={false}>
                  <CardInfoBlock isModal={isModal}>
                    <CardHeading>{"Outreach and Tracking Interest"}</CardHeading>
                    <CardText>
                      This option will provide ClearPath users information about your organization and track interested
                      users in a roster.
                    </CardText>
                    {/* TODO: fix image size so mobile view isn't zoomed out */}
                    <img src={OutreachPreview} alt="shows preview of outreach category" width={"100%"} />
                  </CardInfoBlock>
                </ScrollLock>
              </CategoryContainer>

              <CategoryContainer
                isModal={isModal}
                variant={showVariant(ViewPreferenceEnum.REVIEWING)}
                onClick={() => setViewPreference(ViewPreferenceEnum.REVIEWING)}
              >
                <ScrollLock isActive={false}>
                  <CardInfoBlock isModal={isModal}>
                    <CardHeading>{"Reviewing and Processing Applicants"}</CardHeading>
                    <CardText>
                      In addition to Outreach and Tracking Interest features, this option will allow your organization
                      to accept and decline applicants at any time within the application dashboard.
                    </CardText>
                    <img src={ReviewApplicantPreview} alt="shows preview of applicant review category" width="100%" />
                  </CardInfoBlock>
                </ScrollLock>
              </CategoryContainer>
            </StyledForm>
          );
        }}
      </Formik>
    </LargeContainer>
  );
};
