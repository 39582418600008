import React from "react";
import { SortByPopupProps } from "./types";
import { StyledPopup, SortOption } from "./styles";

export const SortByPopup: React.FC<SortByPopupProps> = ({ trigger, sortField, setSortField }) => {
  return (
    <StyledPopup trigger={trigger} position="bottom right">
      <SortOption onClick={() => setSortField("dateApplied")} selected={sortField === "dateApplied"}>
        Date
      </SortOption>
      <SortOption onClick={() => setSortField("name")} selected={sortField === "name"}>
        Name
      </SortOption>
    </StyledPopup>
  );
};
