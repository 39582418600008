import styled, { css } from "styled-components";
import { COLORS } from "config/styles/colors";

import { StyledInputFieldProps, StyledInputProps } from "./types";

export const InputContainer = styled.div`
  width: 100%;
`;

export const InputLabel = styled.label`
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
`;

export const InputField = styled.div<StyledInputFieldProps>`
  position: relative;

  ${(p) => css`
    padding-bottom: ${p.withErrorMessage && "20px"};
    .show-password-icon {
      position: absolute;
      top: 16px;
      right: 16px;
      cursor: pointer;
      path {
        fill: ${p.showPassword ? p.theme.colors.input.svg.default : p.theme.colors.input.color.placeholder};
      }
    }
  `}
`;

export const StyledTextArea = styled.textarea<StyledInputProps>`
  height: ${(props) => props?.height || "120px"};
  width: ${(props) => props?.width || "100%"};
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  border-radius: 8px;
  outline: none;
  border-bottom: 2px solid ${COLORS.blue};
  resize: none;
  padding: 15px;
 
  {${(p) => css`
    padding-left: ${p.withIcon ? "48px" : "16px"};
    padding-right: ${p.variant === "search" ? "70px" : "48px"};
    color: ${p.theme.colors.input.color.text};
    background-color: ${p.theme.colors.input.bg.default};
    border: 1px solid ${p.error ? p.theme.colors.border.error : p.theme.colors.border.default};
    transition: ${p.theme.transition};

    > svg path {
      transition: ${p.theme.transition};
      stroke: ${p.theme.colors.input.svg.default};
    }

    :hover {
      border-color: ${p.theme.colors.border.hover};
    }

    :focus {
      border-color: ${p.theme.colors.border.active};
    }

    ::placeholder {
      color: ${p.theme.colors.input.color.placeholder};
    }

    :disabled {
      background-color: ${p.theme.colors.input.bg.disabled};
      > svg path {
        stroke: ${p.theme.colors.input.svg.disabled};
      }
    }
  `}
`;

export const NotesInput = styled.input<StyledInputProps>`
  height: ${(props) => props?.height || "56px"};
  width: ${(props) => props?.width || "100%"};
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  outline: none;
  border: transparent;
  border-bottom: 2px solid ${COLORS.blue};
  background: ${COLORS.secondaryBlue};
  display: inline-block;

  ::placeholder {
    color: ${COLORS.greyPlaceholder};
  }
`;

export const ClearSearchBtn = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: ${(p) => p.theme.colors.blue};
`;

export const IconBlock = styled.span`
  display: block;
  position: absolute;
  top: 16px;
  left: 16px;
`;

export const ErrorText = styled.p`
  position: absolute;
  left: 0;
  bottom: 0px;
  margin-top: 4px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: ${(p) => p.theme.colors.input.color.error};
`;
