import styled, { css } from "styled-components";
import { device } from "utils/device";

interface ModalProps {
  isModal: boolean;
  variant: "active" | "default";
}

export const OrganizationContainer = styled.div<ModalProps>`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  flex: 0 1 calc((100% - 24px) / 2);
  padding: 24px;
  box-shadow: 0px 12px 56px 0px rgba(109, 162, 223, 0.16);
  border-radius: 16px;
  width: 100%;
  margin: 1rem 0;
  justify-content: center;

  @media ${device.tablet} {
    flex: none;
    width: 100%;

    ${({ isModal }) => css`
      height: ${isModal ? "100%" : "unset"};
      border-radius: ${isModal ? "0" : "16px"};
    `}
  }

  ${(p) => css`
    background-color: ${p.theme.colors.bg.main};
    border: 2px solid ${p.theme.colors.border[p.variant]};
  `}
`;
