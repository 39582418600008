import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserAppStats, Application } from "types";

type ApplicationInitialState = {
  collection: Application[];
  stats: UserAppStats | null;
  total: number;
};

const applicationInitialState: ApplicationInitialState = {
  collection: [],
  stats: null,
  total: 0,
};

const applicationSlice = createSlice({
  name: "application",
  initialState: applicationInitialState,
  reducers: {
    updateApps(state, action: PayloadAction<typeof applicationInitialState>) {
      state = { ...state, ...action.payload };
      return state;
    },
  },
});

const {
  reducer: applicationReducer,
  actions: { updateApps },
} = applicationSlice;

export type UserState = typeof applicationInitialState;
export { updateApps, applicationReducer };
