import { LatLng, Organization, Timestamp, UserDetails } from "types";

/**
 * Find distance from 1st coordinate to 2nd coordinate. Unit is in miles.
 */
export const calculateDistanceByCoord = (coordinate1: LatLng, coordinate2: LatLng) => {
  // Credited to: https://cloud.google.com/blog/products/maps-platform/how-calculate-distances-map-maps-javascript-api
  const R = 3958.8; // Radius of the Earth in miles
  const rlat1 = coordinate1.lat * (Math.PI / 180); // Convert degrees to radians
  const rlat2 = coordinate2.lat * (Math.PI / 180);
  const difflat = rlat2 - rlat1;
  const difflon = (coordinate2.lng - coordinate1.lng) * (Math.PI / 180);

  const d =
    2 *
    R *
    Math.asin(
      Math.sqrt(
        Math.sin(difflat / 2) * Math.sin(difflat / 2) +
          Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2),
      ),
    );
  return d;
};

/**
 * Format distance to say < 0.1 miles or actual distance rounded to nearest decimal
 *
 * Ex. 3.19 -> < 3.1 miles
 */
export const formatDistance = (distance: number) => `${distance < 0.1 ? "< 0.1" : distance.toFixed(1)} miles`;

/**
 *  Return whether there's any overlap between two arrays
 */
export const arrayContainsAny = (arr1: any[] = [], arr2: any[] = []) => arr1.some((i) => arr2.includes(i));

/**
 *  Case-insensitives search to check whether the query string is a substring of the target string
 */
export const containsSubstring = (target: string = "", query: string = "") =>
  target.toLowerCase().includes(query.toLowerCase());

/**
 *   Convert from Firestore Timestamp to JS Date
 */
export const timestampToDate = (t: Timestamp): Date => new Date(t.Seconds * 1000 + t.Nanoseconds / 1000000);

/**
 *  Get user's full name from user object
 */
export const getFullName = (u: UserDetails): string => u.firstName ?? "" + u.lastName ?? "";

/**
 *  Compare user's names to sort in ascending order
 */
export const compareUsers = (a: UserDetails, b: UserDetails): number => {
  const aName = getFullName(a);
  const bName = getFullName(b);
  if (aName < bName) {
    return -1;
  }
  if (aName > bName) {
    return 1;
  }
  return 0;
};

/**
 *  Type guard to assert account as either User or Organization
 */
export const accountIsUser = (account: UserDetails | Organization, accountIsUser: boolean): account is UserDetails => {
  return accountIsUser;
};
