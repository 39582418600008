import styled from "styled-components";

export const LabelText = styled.span`
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: ${(p) => p.theme.colors.textPrimary};
`;

export const SelectErrorText = styled.span`
  position: absolute;
  left: 0;
  bottom: 0;
  margin-top: 4px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: ${(p) => p.theme.colors.input.color.error};
`;
