import { Form } from "formik";
import styled, { css } from "styled-components";
import { device } from "utils/device";

export interface ModalProps {
  isModal: boolean;
  variant: "active" | "default";
}

export const LargeContainer = styled.div<ModalProps>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  padding: 24px;
  min-height: 420px;
  height: 100%;
  gap: 12px;
  margin-top: 2rem;

  @media ${device.tablet} {
    position: relative;
    width: 100%;
    gap: 12px;

    ${({ isModal }) => css`
      height: ${isModal ? "100%" : "unset"};
      border-radius: ${isModal ? "0" : "16px"};
    `}
  }

  @media ${device.tablet} {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: row;

  @media ${device.tablet} {
    position: absolute;
    margin-top: 400px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const CategoryContainer = styled.div<ModalProps>`
  display: flex;
  text-align: center;
  flex-direction: column;
  flex: 0 1 calc((100% - 24px) / 2);
  padding: 2.75%;
  box-shadow: 0px 12px 56px 0px rgba(109, 162, 223, 0.16);
  border-radius: 16px;
  margin-left: 1rem;

  @media ${device.tablet} {
    flex: none;
    margin-left: 0;
    width: 90%;

    ${({ isModal }) => css`
      height: ${isModal ? "100%" : "unset"};
      box-shadow: ${isModal ? "none" : "0px 12px 56px 0px rgba(109, 162, 223, 0.16)"};
      border-radius: ${isModal ? "0" : "16px"};
    `}
  }

  ${(p) => css`
    background-color: ${p.theme.colors.bg.main};
    border: 2px solid ${p.theme.colors.border[p.variant]};
  `}
`;

export const ErrorText = styled.p`
  font-size: 24px;
  font-weight: 500;
  line-height: 16px;
  color: ${(p) => p.theme.colors.input.color.error};
`;
