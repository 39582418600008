import styled from "styled-components";

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
  overflow: auto;
  thead {
    display: table-header-group;
    color: #7791aa;
  }
  @media (max-width: 768px) {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
  th {
    text-wrap: normal;
    align-items: space-between;
    background-color: #ffffff;
    text-align: left;
    padding-left: 3%;
    padding-top: 1%;
    padding-right: 4%;
    width: 20%;
    text-color: #7791aa;
  }
  th:last-child {
    text-align: right;
  }
  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }
  tr:nth-child(odd) {
    background-color: #f7faff;
  }
  tr:last-child {
    border-bottom: 0.5rem solid #ffffff;
  }
  tr {
    border-left: 0.5rem solid #ffffff;
    border-right: 0.5rem solid #ffffff;
  }
  td {
    padding: 1%;
    padding-left: 3%;
    padding-right: 3%;
    text-align: left;
    vertical-align: middle;
  }
  td:nth-child(3) {
    width: 35%;
  }
  td:last-child {
    text-align: right;
    width: 30%;
  }
`;

export const Icon = styled.span`
  padding-left: 2%;
`;
