import { FormikInput } from "components/formik-input";
import { InputLabel } from "components/input/styles";
import { FieldArray } from "formik";
import { AddReqText } from "pages/organization-dashboard/organization-profile/styles";

interface RequirementsProps {
  requirements: string[];
}

export const RequirementsInput = ({ requirements }: RequirementsProps) => {
  return (
    <>
      <InputLabel>Requirements (optional)</InputLabel>
      <FieldArray
        name="requirements"
        render={(arrayHelpers) => (
          <>
            {requirements.map((req, index) => (
              <FormikInput
                key={index}
                name={`requirements.${index}`}
                iconButton="close"
                handleIconButtonClick={() => arrayHelpers.remove(index)}
                placeholder={`Requirements ${index + 1}`}
              />
            ))}
            <AddReqText type="button" onClick={() => arrayHelpers.push("")}>
              + Add requirement
            </AddReqText>
          </>
        )}
      />
    </>
  );
};
