import { useRef, useState } from "react";
import { IconButton, Input, FiltersPopup, SortByPopup } from "components";
import { IconContainer, SearchBarContainer, SearchToolsBlock } from "./styles";
import { IconVariant, SearchBarProps } from "./types";
import { useResponsive } from "hooks";

export const ApplicantSearchBar = ({
  tab,
  onSubmit,
  status,
  setStatus,
  dateRange,
  setDateRange,
  sortField,
  setSortField,
}: SearchBarProps) => {
  const { isMobile } = useResponsive();
  const toolsBlockRef = useRef<HTMLDivElement | null>(null);

  const [searchValue, setSearchValue] = useState("");

  const getIconVariantProps = (): IconVariant => ({
    icon: tab === "Pending" ? "swap" : "filter",
    text: !isMobile ? (tab === "Pending" ? "Sort by" : "Show filters") : undefined,
  });

  return (
    <SearchBarContainer>
      <SearchToolsBlock ref={toolsBlockRef}>
        <Input
          icon="search"
          value={searchValue}
          placeholder="Search"
          withErrorMessage={false}
          onSearchClear={() => setSearchValue("")}
          onChange={(e) => setSearchValue(e.target.value.trim())}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onSubmit(searchValue);
            }
          }}
        />
      </SearchToolsBlock>
      <IconContainer>
        {tab === "Pending" ? (
          <SortByPopup
            trigger={<IconButton {...getIconVariantProps()} />}
            sortField={sortField}
            setSortField={setSortField}
          />
        ) : (
          <FiltersPopup
            trigger={<IconButton {...getIconVariantProps()} />}
            status={status}
            setStatus={setStatus}
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
        )}
      </IconContainer>
    </SearchBarContainer>
  );
};
