import { normalize } from "styled-normalize";
import { createGlobalStyle } from "styled-components";
import { device } from "utils/device";
interface RootProps {
  windowSize: {
    height: number;
  };
  overflow: string;
}

export const GlobalStyles = createGlobalStyle<RootProps>`
    ${normalize};
    #root {
      // We use innerHeight instead of vh here to avoid viewport issues on mobile
      // This gets passed in from App via @react-use
      height: ${({ windowSize }) => windowSize.height}px;
    }
    
    *, ::before, ::after {
        box-sizing: border-box;
        padding: 0;
        margin: 0;
        font-family: 'Lato', Arial, sans-serif;
    }

    html, body {
      color: ${(p) => p.theme.colors.textPrimary};

      @media ${device.tablet} {
        overflow: ${({ overflow }) => overflow};
        position: fixed;
        height: 100%;
        width: 100%;
      }
    }

    input {
      color: ${(p) => p.theme.colors.textPrimary};
    }

    h1,h2,h3,h4,h5,h6 {
      margin: 0;
    }

    a {
      text-decoration: none;
    }

    ul {
      list-style-type: none;
    }

    button {
      border: none;
      color: inherit;
      background-color: inherit;
      cursor: pointer;
    }

    q {
      quotes: none;
    }
`;
