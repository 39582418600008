import { Portal } from "react-portal";
import ScrollLock from "react-scrolllock";

import { Icon } from "components";

import { ModalProps } from "./types";
import { Container, Backdrop, CloseIconButton, ModalContentWrap } from "./styles";

export const Modal = ({
  maxWidth,
  isPopup,
  children,
  closeModal,
  portalNode,
  onBackdropClick,
  contentWrapClassName,
  isOpen,
}: ModalProps): JSX.Element => {
  return (
    <>
      {isOpen && (
        <Portal node={portalNode}>
          <Container onClick={(e) => e.stopPropagation()}>
            <ScrollLock />
            <Backdrop isPopup={isPopup} onClick={onBackdropClick} />
            <ModalContentWrap className={contentWrapClassName} maxWidth={maxWidth}>
              <CloseIconButton onClick={closeModal}>
                <Icon icon="close" />
              </CloseIconButton>
              {children}
            </ModalContentWrap>
          </Container>
        </Portal>
      )}
    </>
  );
};
