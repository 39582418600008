import styled from "styled-components";

import { device } from "utils/device";

export const MainContainer = styled.div`
  background-color: ${(p) => p.theme.colors.bg.layout};
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 292px;
  background-color: ${(p) => p.theme.colors.bg.layout};
  @media ${device.tablet} {
    margin-left: 0;
    margin-top: 56px;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
  flex-grow: 1;

  @media ${device.tablet} {
    padding: 0px;
    overflow: scroll;
    height: 300px;
  }
`;

export const FixedSidebar = styled.aside`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 292px;
  background-color: ${(p) => p.theme.colors.bg.main};
`;
