import { communitiesAssets, resourcesAssets } from "types";
import {
  ConfirmDetailsContainer,
  SubConfirmContainer,
  ConfirmText,
  ConfirmSubText,
  SubContainer,
  // ReqListItem,
  // ReqList,
  TypesText,
  TypesTextWrapper,
  HeadingPlusText,
  TextBlock,
} from "./styles";
import { ConfirmNonprofitProps } from "./types";
import { useEffect } from "react";

export const ConfirmNonprofitDetails = ({
  orgDetailsForm,
  orgContactForm,
  taxDetailsForm,
  setFormId,
}: ConfirmNonprofitProps) => {
  const { borough, address, ageRange, additionalResources, targetCommunities, description, requirements, link } =
    orgDetailsForm;

  const { orgName, orgEmail, phoneNumber, orgType } = orgContactForm;

  const { type, ein, taxNumber } = taxDetailsForm;

  useEffect(() => {
    setFormId("confirm");
  }, [setFormId]);

  return (
    <SubContainer>
      <ConfirmDetailsContainer>
        <SubConfirmContainer>
          <HeadingPlusText>
            <ConfirmText>Organization Name</ConfirmText>
            <ConfirmSubText>{orgName}</ConfirmSubText>
          </HeadingPlusText>
          <HeadingPlusText>
            <ConfirmText>Email</ConfirmText>
            <ConfirmSubText>{orgEmail}</ConfirmSubText>
          </HeadingPlusText>
          <HeadingPlusText>
            <ConfirmText>Borough</ConfirmText>
            <ConfirmSubText>{borough}</ConfirmSubText>
          </HeadingPlusText>
          <HeadingPlusText>
            <ConfirmText>Age Range</ConfirmText>
            <ConfirmSubText>
              {ageRange.min} - {ageRange.max}
            </ConfirmSubText>
          </HeadingPlusText>
        </SubConfirmContainer>
        <SubConfirmContainer>
          <HeadingPlusText>
            <ConfirmText>Primary Category</ConfirmText>
            <ConfirmSubText>{orgType}</ConfirmSubText>
          </HeadingPlusText>
          <HeadingPlusText>
            <ConfirmText>Phone Number</ConfirmText>
            <ConfirmSubText>{phoneNumber}</ConfirmSubText>
          </HeadingPlusText>
          <HeadingPlusText>
            <ConfirmText>Street Address</ConfirmText>
            <ConfirmSubText>{address}</ConfirmSubText>
          </HeadingPlusText>
          <HeadingPlusText>
            <ConfirmText>{type === "ein" ? "EIN" : "Tax Number"}</ConfirmText>
            <ConfirmSubText>{type === "ein" ? ein : taxNumber}</ConfirmSubText>
          </HeadingPlusText>
        </SubConfirmContainer>
      </ConfirmDetailsContainer>

      {targetCommunities.length > 0 && (
        <HeadingPlusText>
          <ConfirmText>Target Communities</ConfirmText>
          <TypesTextWrapper>
            {targetCommunities.map((value, key) => (
              <TextBlock key={key}>
                <TypesText>{communitiesAssets[value].label}</TypesText>
              </TextBlock>
            ))}
          </TypesTextWrapper>
        </HeadingPlusText>
      )}

      {additionalResources.length > 0 && (
        <HeadingPlusText>
          <ConfirmText>Additional Resources</ConfirmText>
          <TypesTextWrapper>
            {additionalResources.map((value, key) => (
              <TextBlock key={key}>
                <TypesText>{resourcesAssets[value].label}</TypesText>
              </TextBlock>
            ))}
          </TypesTextWrapper>
        </HeadingPlusText>
      )}

      <HeadingPlusText>
        <ConfirmText>Description</ConfirmText>
        <ConfirmSubText>{description}</ConfirmSubText>
      </HeadingPlusText>

      <HeadingPlusText>
        <ConfirmText>Requirements</ConfirmText>
        {/* We will change this to a list later
           <ReqList> 
            <ReqListItem>{requirements}</ReqListItem>
          </ReqList> */}
        <ConfirmSubText>{requirements}</ConfirmSubText>
      </HeadingPlusText>

      {link && (
        <HeadingPlusText>
          <ConfirmText>Link</ConfirmText>
          <ConfirmSubText>{link}</ConfirmSubText>
        </HeadingPlusText>
      )}
    </SubContainer>
  );
};
