import { COLORS } from "config/styles/colors";
import styled from "styled-components";
import { device } from "utils/device";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 1rem;
`;

export const SubContainer = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  // margin: auto;
  padding: 2rem 0;
  row-gap: 32px;
  justify-content: center;

  @media ${device.tablet} {
    width: 80%;
  }
`;

export const ConfirmDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  column-gap: 32px;

  @media ${device.tablet} {
    flex-direction: column;
    row-gap: 32px;
  }
`;

export const SubConfirmContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  width: 100%;
`;

export const HeadingPlusText = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

export const ConfirmDetailsHeading = styled.h3`
  font-size: 32px;
  font-weight: 800;
  line-height: 40px;
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
`;

export const ConfirmText = styled.h3`
  font-size: 16px;
  line-height: 24px;
`;

export const ConfirmSubText = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;

export const TypesText = styled.p`
  font-size: 12px;
  line-height: 30px;
  background-color: ${COLORS.secondaryBlue};
  border-radius: 0.2rem;
  color: ${COLORS.blue};
  padding: 0.4em 1em 0.4em 1em;
  display: inline;
`;

export const TypesTextWrapper = styled.div`
  flex-direction: row;
`;

export const TextBlock = styled.div`
  display: inline-block;
  padding-right: 10px;
`;

export const ButtonsBlock = styled.div`
  display: flex;
  column-gap: 16px;
  width: 45%;
  margin: auto;
  margin-bottom: 32px;
  margin-top: 32px;

  @media ${device.tablet} {
    flex-direction: column;
    row-gap: 16px;
  }
`;

export const ReqList = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

export const ReqListItem = styled.li`
  position: relative;
  padding-left: 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;

  ::before {
    content: "";
    display: block;
    position: absolute;
    top: 12px;
    left: 0;
    transform: translateY(-50%);
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: ${(p) => p.theme.colors.blue};
  }
`;
