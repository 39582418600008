import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface errorState {
  error: { type: string; error: any } | null;
}

const initialState: errorState = {
  error: null,
};

const errorSlice = createSlice({
  name: "error",
  initialState: initialState,
  reducers: {
    throwError(state, action: PayloadAction<{ type: string; error: any }>) {
      state.error = action.payload;
    },
    removeError(state) {
      state.error = null;
    },
  },
});

const {
  reducer: errorReducer,
  actions: { throwError, removeError },
} = errorSlice;

export type ErrorState = typeof initialState;
export { throwError, removeError, errorReducer, initialState };
