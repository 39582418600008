import { useMemo, useState } from "react";

import { ActionButton, Icon } from "components";

import {
  StyledOption,
  FixedContainer,
  OptionsContainer,
  ContentContainer,
  StyledMobileSelect,
  MobileSelectContainer,
  ActionButtonContainer,
} from "./styles";

import { SelectProps } from "../types";
import { LabelText, SelectErrorText } from "../styles";
import { useField } from "formik";

export const MobileSelect = ({
  label,
  error,
  isError,
  options,
  selectedValue,
  onChange,
  placeholder,
  name,
}: SelectProps) => {
  const [selectVisible, setSelectVisible] = useState(false);
  const [, , helpers] = useField({ name: name || "" });

  const handleChange = (value: string) => {
    setSelectVisible(false);
    helpers.setValue(value);
  };

  const value = useMemo(() => {
    if (!selectedValue || options?.length === 0) return undefined;
    const option = options?.find((item) => item.value === selectedValue);
    return option?.label;
  }, [selectedValue, options]);

  const withValue = useMemo(() => (value ? true : false), [value]);

  return (
    <MobileSelectContainer>
      <LabelText>{label}</LabelText>

      <StyledMobileSelect
        withValue={withValue}
        selectVisible={selectVisible}
        onClick={() => setSelectVisible(!selectVisible)}
      >
        {value || placeholder}
        <Icon icon="chevron-down" className="chevron-icon" />
      </StyledMobileSelect>

      {isError && <SelectErrorText>{error}</SelectErrorText>}

      {selectVisible && (
        <FixedContainer>
          <OptionsContainer>
            <ContentContainer>
              {options?.map((item, index) => (
                <StyledOption type="button" key={index.toString()} onClick={() => handleChange(item.value)}>
                  {item.label}
                </StyledOption>
              ))}
            </ContentContainer>

            <ActionButtonContainer>
              <ActionButton type="button" size="fullWidth" variant="secondary" onClick={() => setSelectVisible(false)}>
                Cancel
              </ActionButton>
            </ActionButtonContainer>
          </OptionsContainer>
        </FixedContainer>
      )}
    </MobileSelectContainer>
  );
};
