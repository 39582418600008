import { useEffect, useState } from "react";

import { DirectoryCardProps } from "types";
import { Confirm, Modal, DirectoryCard } from "components";

import { ConfirmHandler } from "./types";
import { useTypedSelector } from "store";
import { selectUserDetails } from "store/user/selectors";
import { useGetApplications, useSubmitApplication } from "store/application/api";
import { ROUTES } from "config";
import { useHistory } from "react-router";

export const useApplySupportModal = (onConfirm?: ConfirmHandler) => {
  const history = useHistory();
  const [DirectoryCardContent, setDirectoryCardContent] = useState<DirectoryCardProps | null>(null);
  const [isConfirmation, setIsConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refetchedApps, setRefetchedApps] = useState(false);

  const closeApplySupportModal = () => setDirectoryCardContent(null);

  const openConfirmModal = () => setIsConfirmation(true);
  const closeConfirmModal = () => setIsConfirmation(false);

  const userId = useTypedSelector((state) => selectUserDetails(state).id);
  const [submitApp, { isSuccess }] = useSubmitApplication();
  const { data: userApps } = useGetApplications(userId, { skip: !refetchedApps });

  const handleConfirm = async (orgId: string) => {
    setLoading(true);
    onConfirm?.();

    await submitApp({ orgId, userId });
    setRefetchedApps(true);
  };

  useEffect(() => {
    if (refetchedApps && isSuccess) {
      closeConfirmModal();
      closeApplySupportModal();
      console.log("userApps", userApps);

      history.push(`${ROUTES.Home}/?submitted`);
    }
  }, [refetchedApps, userApps, isSuccess, history]);

  const applySupportModal = (
    <>
      <Modal
        maxWidth="400px"
        closeModal={closeConfirmModal}
        onBackdropClick={closeConfirmModal}
        isOpen={isConfirmation && loading}
      >
        {() => <div>Completed!</div>}
      </Modal>

      {!!DirectoryCardContent && (
        <Modal
          maxWidth="992px"
          closeModal={closeApplySupportModal}
          onBackdropClick={closeApplySupportModal}
          isOpen={!!DirectoryCardContent && !isConfirmation}
        >
          <DirectoryCard isModal showDate onApply={openConfirmModal} {...DirectoryCardContent} />
        </Modal>
      )}

      {!!DirectoryCardContent && (
        <Modal
          maxWidth="400px"
          closeModal={closeConfirmModal}
          onBackdropClick={closeConfirmModal}
          isOpen={isConfirmation && !!DirectoryCardContent}
        >
          <Confirm
            onApply={() => handleConfirm(DirectoryCardContent.id)}
            onCancel={closeConfirmModal}
            confirmTitle="Confirmation"
            confirmDecision="By clicking apply, you are authorizing Clearpath to send your information to this org."
            confirmButton="Apply"
          />
        </Modal>
      )}
    </>
  );

  return { applySupportModal, openApplySupportModal: setDirectoryCardContent };
};
