import { ActionButton } from "components";

import { ConfirmProps } from "./types";
import { Container, ConfirmContainer, ConfirmHeading, ConfirmText, ButtonsBlock } from "./styles";

export const Confirm = ({ onCancel, onApply, confirmTitle, confirmDecision, confirmButton }: ConfirmProps) => {
  return (
    <Container>
      <ConfirmContainer>
        <ConfirmHeading>{confirmTitle}</ConfirmHeading>

        <ConfirmText>
          {confirmDecision}
          <br />
        </ConfirmText>

        <ButtonsBlock>
          <ActionButton variant="secondary" size="fullWidth" onClick={onCancel}>
            Cancel
          </ActionButton>
          <ActionButton variant="primary" size="fullWidth" onClick={onApply}>
            {confirmButton}
          </ActionButton>
        </ButtonsBlock>
      </ConfirmContainer>
    </Container>
  );
};
