import { IconType } from "components/icon";

export type ValueOf<T> = T[keyof T];

export type ValueOfEnum<T extends string> = `${T}`;

export type LatLng = { lat: number; lng: number };

/**
 * Categories for map filters
 */
export enum FilterCategoryEnum {
  LOCATION_TYPE = "locationType",
  DISTANCE = "distance",
  AGE_GROUPS = "ageGroups",
  TARGET_COMMUNITIES = "targetCommunities",
  RESOURCES_OFFERED = "resourcesOffered",
}

/**
 * Categories for map filters
 */
export const filterCategoryAssets: Record<FilterCategoryEnum, { label: string }> = {
  [FilterCategoryEnum.RESOURCES_OFFERED]: { label: "All Resources" },
  [FilterCategoryEnum.TARGET_COMMUNITIES]: { label: "Communities" },
  [FilterCategoryEnum.AGE_GROUPS]: { label: "Age Group" },
  [FilterCategoryEnum.DISTANCE]: { label: "Distance" },
  [FilterCategoryEnum.LOCATION_TYPE]: { label: "Location" },
};

export interface Timestamp {
  Seconds: number;
  Nanoseconds: number;
}

//TODO: figure out difference between career development and professional services
/**
 * Serves as Organization Type options and Additional Resources tags options
 *
 * Note: organizations cannot choose their type as WATER_FOUNTAINS or PUBLIC_RESTROOMS but
 * they can as Additional Resources
 */
export enum ResourceCategoryEnum {
  PUBLIC_RESTROOMS = "publicRestrooms",
  PUBLIC_COMPUTERS = "publicComputers",
  HOUSING_SUPPORT = "shelter",
  MENTAL_WELLNESS = "mentalWellness",
  ACADEMIC_DEVELOPMENT = "academic",
  FOOD = "food",
  PHYSICAL_HEALTH = "physicalHealth",
  CAREER_DEVELOPMENT = "professionalServices",
  LEGAL_HELP = "legalHelp",
  WATER_FOUNTAINS = "waterFountains",
}

export enum BoroughEnum {
  MANHATTAN = "manhattan",
  BROOKLYN = "brooklyn",
  QUEENS = "queens",
  BRONX = "bronx",
  STATEN_ISLAND = "statenIsland",
}

/**
 * Serves as Target Communities tags options
 */
export enum TargetCommunityEnum {
  LGBTQ = "lgbtq",
  MEN = "men",
  WOMEN = "women",
  SPECIAL_NEEDS = "specialNeeds",
  VETERANS = "veterans",
  DISABILITIES = "disabilities",
  FAMILY = "family",
}

export enum ViewPreferenceEnum {
  OUTREACH = "outreach",
  REVIEWING = "reviewing",
}

/**
 * Age filters for map
 */
export enum AgeGroupEnum {
  YOUTH = "youth",
  TEEN = "teen",
  YOUNG_ADULT = "youngAdult",
  ADULT = "adult",
}

/**
 * Distance filters for map
 */
export enum DistanceEnum {
  PUBLIC_TRANSIT = "publicTransit",
  BIKING = "biking",
  WALKING = "walking",
  // ADULT = "adult",
}

/**
 * Location type filters for map
 */
export enum LocationTypeEnum {
  IN_PERSON = "inPerson",
  REMOTE = "remote",
}

export const distanceAssets: Record<DistanceEnum, { label: string; distance: number }> = {
  [DistanceEnum.PUBLIC_TRANSIT]: { distance: 5, label: "Public Transit (<5 mi.)" },
  [DistanceEnum.BIKING]: { distance: 2, label: "Biking (<2 mi.)" },
  [DistanceEnum.WALKING]: { distance: 1, label: "Walking (<1 mi.)" },
} as const;

export const locationAssets: Record<LocationTypeEnum, { label: string }> = {
  [LocationTypeEnum.IN_PERSON]: { label: "In-Person" },
  [LocationTypeEnum.REMOTE]: { label: "Online" },
} as const;

export const MAX_AGE = 100;
export const MIN_AGE = 0;

export const ageGroupsAssets: Record<AgeGroupEnum, { label: string; min: number; max: number }> = {
  [AgeGroupEnum.YOUTH]: { min: MIN_AGE, max: 12, label: "Youth (<13 years)" },
  [AgeGroupEnum.TEEN]: { min: 13, max: 17, label: "Teen (13-17 years)" },
  [AgeGroupEnum.YOUNG_ADULT]: { min: 18, max: 25, label: "Young Adult (18-25 years)" },
  [AgeGroupEnum.ADULT]: { min: 26, max: MAX_AGE, label: "Adult (25+ years)" },
} as const;

export const resourcesAssets: Record<ResourceCategoryEnum, { icon: IconType; label: string }> = {
  [ResourceCategoryEnum.HOUSING_SUPPORT]: { label: "Housing Support", icon: IconType.home },
  [ResourceCategoryEnum.FOOD]: { label: "Food", icon: IconType.food },
  [ResourceCategoryEnum.PUBLIC_RESTROOMS]: { label: "Public Restrooms", icon: IconType.toilet },
  [ResourceCategoryEnum.PUBLIC_COMPUTERS]: { label: "Public Computers", icon: IconType.laptop },
  [ResourceCategoryEnum.WATER_FOUNTAINS]: { label: "Water Fountains", icon: IconType.water },
  [ResourceCategoryEnum.PHYSICAL_HEALTH]: { label: "Physical Health", icon: IconType.health },
  [ResourceCategoryEnum.MENTAL_WELLNESS]: { label: "Mental Wellness", icon: IconType.heart },
  [ResourceCategoryEnum.ACADEMIC_DEVELOPMENT]: { label: "Academic Development", icon: IconType.book },
  [ResourceCategoryEnum.CAREER_DEVELOPMENT]: { label: "Career Development", icon: IconType["brief-case"] },
  [ResourceCategoryEnum.LEGAL_HELP]: { label: "Legal Help", icon: IconType.legal },
};

export const viewPreferenceOptions = [
  { value: ViewPreferenceEnum.OUTREACH, label: "Outreach and Tracking Interest" },
  { value: ViewPreferenceEnum.REVIEWING, label: "Reviewing and Processing Applicants" },
];

export const inPersonOptions = [
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" },
];

export const boroughOptions = [
  { value: BoroughEnum.MANHATTAN, label: "Manhattan" },
  { value: BoroughEnum.BROOKLYN, label: "Brooklyn" },
  { value: BoroughEnum.QUEENS, label: "Queens" },
  { value: BoroughEnum.BRONX, label: "Bronx" },
  { value: BoroughEnum.STATEN_ISLAND, label: "Staten Island" },
];

export const targetCommunityOptions = [
  { value: TargetCommunityEnum.LGBTQ, label: "LGBTQ+" },
  { value: TargetCommunityEnum.MEN, label: "Men" },
  { value: TargetCommunityEnum.WOMEN, label: "Women" },
  { value: TargetCommunityEnum.SPECIAL_NEEDS, label: "Special Needs" },
  { value: TargetCommunityEnum.VETERANS, label: "Veterans" },
  { value: TargetCommunityEnum.DISABILITIES, label: "Disabilities" },
  { value: TargetCommunityEnum.FAMILY, label: "Family" },
];
export const communitiesAssets: Record<TargetCommunityEnum, { icon: string; label: string }> = {
  [TargetCommunityEnum.LGBTQ]: { label: "LGBTQ+", icon: "lgbtq" },
  [TargetCommunityEnum.MEN]: { label: "Men", icon: "men" },
  [TargetCommunityEnum.WOMEN]: { label: "Women", icon: "women" },
  [TargetCommunityEnum.SPECIAL_NEEDS]: { label: "Special Needs", icon: "special-needs" },
  [TargetCommunityEnum.VETERANS]: { label: "Veterans", icon: "veterans" },
  [TargetCommunityEnum.DISABILITIES]: { label: "Disabilites", icon: "disabilities" },
  [TargetCommunityEnum.FAMILY]: { label: "Family", icon: "family" },
};

export const resourceCategoryOptions = [
  { value: ResourceCategoryEnum.HOUSING_SUPPORT, label: "Housing Support" },
  { value: ResourceCategoryEnum.MENTAL_WELLNESS, label: "Mental Wellness" },
  { value: ResourceCategoryEnum.ACADEMIC_DEVELOPMENT, label: "Academic Development" },
  { value: ResourceCategoryEnum.FOOD, label: "Food" },
  { value: ResourceCategoryEnum.PHYSICAL_HEALTH, label: "Physical Health" },
  { value: ResourceCategoryEnum.CAREER_DEVELOPMENT, label: "Career Development" },
  { value: ResourceCategoryEnum.LEGAL_HELP, label: "Legal Help" },
  { value: ResourceCategoryEnum.WATER_FOUNTAINS, label: "Water Fountains" },
  { value: ResourceCategoryEnum.PUBLIC_RESTROOMS, label: "Public Restrooms" },
];

export interface Details {
  inPerson: string;
  borough: ValueOfEnum<BoroughEnum>;
  address: string;
  lat: number;
  long: number;
  ageRange: { min: number; max: number };
  targetCommunities: ValueOfEnum<TargetCommunityEnum>[];
  additionalResources: ValueOfEnum<ResourceCategoryEnum>[];
  description: string;
  requirements: string[];
  link?: string;
}

export interface ContactInfo {
  orgName: string;
  orgEmail: string;
  phoneNumber: string;
  orgType: ValueOfEnum<ResourceCategoryEnum>;
  password: string;
  confirmPassword: string;
  id?: string;
}

export interface TaxInfo {
  type: string;
  ein: string;
  taxNumber: string;
}

export interface Organization {
  id: string;
  viewPreference: ValueOfEnum<ViewPreferenceEnum>;
  name: string;
  orgType: ValueOfEnum<ResourceCategoryEnum>;
  email: string;
  contactNumber: string;
  password: string;
  inPerson: boolean;
  borough?: ValueOfEnum<BoroughEnum>;
  address?: string;
  ageLower: number;
  ageUpper: number;
  targetCommunities: ValueOfEnum<TargetCommunityEnum>[];
  additionalResources: ValueOfEnum<ResourceCategoryEnum>[];
  description?: string;
  requirements: string[];
  verification: {
    type: string;
    number: string;
  };
  url?: string;
  lat?: number;
  long?: number;
  status?: OrganizationStatusEnum;
}

export interface DirectoryCardProps extends Organization {
  tags?: string[];
  gmapData?: {
    lat: number;
    long: number;
  };
}

export type UserDetails = {
  id: string;
  email: string;
  race: string;
  sexuality: string;
  gender: string;
  dob: string;
  firstName: string;
  lastName: string;
  priorAcademics: {
    level: string;
    school: string;
    department: string;
    specialization: string;
    yearOfGrad: string;
  };
};

export type PublicDevice = {
  address: string;
  borough: string;
  name: string;
  operationTime: string;
  url: string;
  email: string;
  contact_number: string;
  lat: number;
  long: number;
  tags: string[];
};

export type PublicBathroom = {
  id: string;
  address: string;
  borough: string;
  name: string;
  operationTime: string;
  facilityType: string[];
  lat: number;
  long: number;
  tags: string[];
};

export enum StatusTypeEnum {
  PENDING = "pending",
  DECLINED = "declined",
  COMPLETED = "completed",
  SUBMITTED = "submitted",
  ACCEPTED = "accepted",
}

export enum OrganizationStatusEnum {
  UNCLAIMED = "unclaimed",
  PENDING = "pending",
  ACCEPTED = "accepted",
  DECLINED = "declined",
}

export enum ApplicantStatusEnum {
  PENDING = "pending",
  ACCEPTED = "accepted",
  DECLINED = "declined",
}

export type UserAppStats = {
  [K in StatusTypeEnum]: number;
};

export type Application = {
  message?: string;
  orgId: string;
  orgName: string;
  status: string;
  userId: string;
  dateApplied: Timestamp;
  id: string;
};

export type Interaction = {
  userId: string;
  orgId: string;
  lastInteracted: Timestamp;
};

export type InteractionWithUser = Interaction & { user: UserDetails };
export type ApplicationWithUser = Application & { user: UserDetails };

export type ApplicationReviewRequestBody = {
  status: ValueOfEnum<Exclude<ApplicantStatusEnum, ApplicantStatusEnum.PENDING>>;
  message: string;
  userName: string;
  userEmail: string;
  notifyUser: string;
};

export interface DateRange {
  from: string;
  to: string;
}

export type SortFieldOption = "name" | "dateApplied";
