import styled, { css } from "styled-components";
import { device } from "utils/device";

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const ConfirmContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  row-gap: 24px;
  flex: 0 1 100%;
  padding: 24px;
  box-shadow: 0px 12px 56px 0px rgba(109, 162, 223, 0.16);
  border-radius: 16px;

  ${(p) => css`
    background-color: ${p.theme.colors.bg.main};
    border: 1px solid ${p.theme.colors.border.default};
  `}

  @media ${device.tablet} {
    flex: none;
  }
`;

export const ConfirmHeading = styled.h3`
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
`;

export const ConfirmText = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;

export const ButtonsBlock = styled.div`
  display: flex;
  column-gap: 16px;

  @media ${device.tablet} {
    flex-direction: column;
    row-gap: 16px;
  }
`;
