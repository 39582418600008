import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Organization } from "types";

type OrgInitialState = {
  collection: Organization[] | null;
  count: number;
  currentOrg: Organization | null;
};

const orgInitialState: OrgInitialState = {
  collection: null,
  count: 0,
  currentOrg: null,
};

const orgSlice = createSlice({
  name: "org",
  initialState: orgInitialState,
  reducers: {
    updateOrgs(state, action: PayloadAction<Pick<OrgInitialState, "collection" | "count">>) {
      state = { ...state, ...action.payload };
      return state;
    },
    updateLoggedInOrg(state, action: PayloadAction<Partial<Organization>>) {
      state.currentOrg = { ...state.currentOrg, ...(action.payload as Organization) };
    },
  },
});

const {
  reducer: orgReducer,
  actions: { updateOrgs, updateLoggedInOrg },
} = orgSlice;

export type UserState = typeof orgInitialState;
export { updateOrgs, updateLoggedInOrg, orgReducer };
