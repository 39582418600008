import { Formik, Form } from "formik";
import { FormikInput, FormikSelect } from "components";
import { FormContainer } from "./styles";
import { OrganizationTaxProps } from "./types";
import { useEffect } from "react";
import { taxValidationSchema } from "./config";

export const OrganizationTaxInfoForm = ({ setForm, setFormId, taxInfo, nextClick }: OrganizationTaxProps) => {
  useEffect(() => {
    setFormId("tax");
  }, [setFormId]);

  const verificationOptions = [
    { label: "EIN", value: "ein" },
    { label: "Tax Number", value: "tax" },
  ];

  return (
    <FormContainer>
      <Formik
        initialValues={taxInfo}
        validationSchema={taxValidationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values) => {
          setForm(values);
          nextClick();
        }}
      >
        {({ values }) => (
          <Form id="tax">
            <FormikSelect
              name="type"
              options={verificationOptions}
              label="Verification Method"
              placeholder="Select verification method"
            />
            {values.type === "ein" ? (
              <FormikInput name="ein" label="EIN" type="text" placeholder="Enter EIN" />
            ) : (
              <FormikInput name="taxNumber" label="Tax Number" type="text" placeholder="Enter tax number" />
            )}
          </Form>
        )}
      </Formik>
    </FormContainer>
  );
};
