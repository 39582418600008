import { useSelector } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router-dom";

import { ROUTES } from "config";
import { selectAuthSessionStatus } from "store/auth/selectors";
import { useTypedSelector } from "store";
import { selectOrgState } from "store/organization/selectors";
import { OrganizationStatusEnum } from "types";

interface PrivateRouteProps extends RouteProps {
  isOrganizationRoute?: boolean;
}

export function PrivateRoute({ path, exact, component, isOrganizationRoute = false }: PrivateRouteProps): JSX.Element {
  const isAuthed = useSelector(selectAuthSessionStatus);
  const { currentOrg } = useTypedSelector(selectOrgState);

  const isOrganization = Boolean(currentOrg);
  const isAcceptedOrganization = Boolean(currentOrg?.status === OrganizationStatusEnum.ACCEPTED);

  if (!isAuthed && path && path[0] !== ROUTES.Home) {
    return <Redirect to={ROUTES.SignIn} />;
  }

  if (isOrganizationRoute) {
    if (!isOrganization) {
      return <Redirect to={ROUTES.Home} />;
    }
  }

  if (!isOrganizationRoute && isOrganization) {
    return <Redirect to={isAcceptedOrganization ? ROUTES.OrganizationHome : ROUTES.OrganizationStatus} />;
  }

  return <Route path={path} exact={exact} component={component} />;
}

interface OrganizationPrivateRouteProps extends RouteProps {}

export function OrganizationPrivateRoute({ path, exact, component }: OrganizationPrivateRouteProps): JSX.Element {
  const { currentOrg } = useTypedSelector(selectOrgState);

  const isAcceptedOrganization = Boolean(currentOrg?.status === OrganizationStatusEnum.ACCEPTED);

  if (path === ROUTES.OrganizationStatus && isAcceptedOrganization) {
    return <Redirect to={ROUTES.OrganizationHome} />;
  }

  if (path !== ROUTES.OrganizationStatus && !isAcceptedOrganization) {
    return <Redirect to={ROUTES.OrganizationStatus} />;
  }

  return <Route path={path} exact={exact} component={component} />;
}

interface PublicRouteProps extends RouteProps {}

export function PublicRoute({ path, exact, component }: PublicRouteProps): JSX.Element {
  const isAuthed = useSelector(selectAuthSessionStatus);
  const { currentOrg } = useTypedSelector(selectOrgState);

  const isOrganization = Boolean(currentOrg);
  const isAcceptedOrganization = Boolean(currentOrg?.status === OrganizationStatusEnum.ACCEPTED);

  if (path === ROUTES.NotFound || !isAuthed) {
    return <Route path={path} exact={exact} component={component} />;
  }

  if (isOrganization) {
    return <Redirect to={isAcceptedOrganization ? ROUTES.OrganizationHome : ROUTES.OrganizationStatus} />;
  }

  // must be signed-in user
  return <Redirect to={ROUTES.Home} />;
}
