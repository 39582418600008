import { Icon } from "components";

import { CheckboxProps } from "./types";
import { LabelContent, CheckboxLabel, StyledCheckbox, CheckboxContainer } from "./styles";

export const Checkbox = ({ label, checked, disabled, ...props }: CheckboxProps) => {
  return (
    <CheckboxLabel>
      <CheckboxContainer disabled={disabled}>
        <StyledCheckbox type="checkbox" checked={checked} disabled={disabled} {...props} />

        <Icon
          icon={checked ? "checkbox-checked" : "checkbox-default"}
          className={checked ? "checkbox-checked" : "checkbox-default"}
        />
      </CheckboxContainer>
      <LabelContent>{label}</LabelContent>
    </CheckboxLabel>
  );
};
