import styled, { css } from "styled-components";
import { device } from "utils/device";
import { COLORS } from "config/styles/colors";

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 24px;
  flex: 0 1 calc((100% - 24px) / 2);
  padding: 24px;
  box-shadow: 0px 12px 56px 0px rgba(109, 162, 223, 0.16);
  border-radius: 16px;
  background-color: "black";
  min-height: 350px;

  @media ${device.tablet} {
    flex: none;
    width: 100%;
  }

  ${(p) => css`
    background-color: ${p.theme.colors.bg.main};
    border: 1px solid ${p.theme.colors.border.default};
  `}
`;

export const CardInfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  @media ${device.tablet} {
    overflow: "auto";
  }
`;

export const CardHeading = styled.h3`
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;
`;

export const CardDate = styled.time`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: ${(p) => p.theme.colors.textSecondary};
`;

export const Notes = styled.h2`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  flex-direction: row;
`;

export const NotesWrapper = styled.div`
  flex-direction: row;
  display: inline-block;
`;

export const Description = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-clamp: 3;
`;

export const ShowMoreBtn = styled.button`
  color: ${COLORS.greyPlaceholder};
`;

export const StyledText = styled.p`
  color: ${COLORS.greyPlaceholder};
  display: inline-block;
`;
