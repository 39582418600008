import { useMediaQuery } from "react-responsive";
import ScrollLock from "react-scrolllock";

import { ActionButton, DeclineApplicantModal, Modal } from "components";
import { device } from "utils/device";

import { IconButton } from "components";
import { COLORS } from "config/styles/colors";

import { useState } from "react";
import {
  CardContainer,
  CardDate,
  CardHeading,
  CardInfoBlock,
  Description,
  Notes,
  NotesWrapper,
  StyledText,
} from "./styles";
import { ReviewApplicantModalProps } from "./types";

import { AcceptApplicantModal } from "components/accept-applicant-modal";
import { ButtonContainer } from "components/accept-applicant-modal/styles";
import { EmailTag } from "components/email";
import { TextArea } from "components/textarea";
import { useDisclosure } from "hooks";

export const ReviewApplicantModal = ({
  name,
  email,
  dateApplied,
  message,
  notes,
  isOpen,
  onReview,
  onClose,
}: ReviewApplicantModalProps) => {
  const isMobile = useMediaQuery({ query: device.tablet });
  const [isEditing, setIsEditing] = useState(false);

  const {
    isOpen: isAcceptApplicantModalOpen,
    open: openAcceptApplicantModal,
    close: closeAcceptApplicantModa,
  } = useDisclosure();
  const {
    isOpen: isDeclineApplicantModalOpen,
    open: openDeclineApplicantModal,
    close: closeDeclineApplicantModal,
  } = useDisclosure();

  return (
    <Modal isOpen={isOpen} closeModal={onClose} maxWidth={"992px"} onBackdropClick={onClose}>
      <CardContainer>
        <ScrollLock isActive={false}>
          <CardInfoBlock>
            <CardDate>{dateApplied ? dateApplied.toLocaleDateString() : ""}</CardDate>
            <CardHeading>{name}</CardHeading>
            <EmailTag email={email} />
            <Description>{message}</Description>

            <Notes>
              {!isEditing ? (
                <NotesWrapper>
                  <StyledText
                    onClick={() => {
                      setIsEditing(true);
                    }}
                  >
                    Add Note
                  </StyledText>
                  &nbsp;
                  <IconButton
                    width="12px"
                    iconProps={{ width: "100%", color: COLORS.greyPlaceholder, fill: COLORS.greyPlaceholder }}
                    backgroundColor={COLORS.white}
                    stroke-width="15px"
                    display="inline-block"
                    icon="pencil"
                    onClick={() => setIsEditing(true)}
                  />
                </NotesWrapper>
              ) : (
                <>
                  <StyledText>Notes: &nbsp;</StyledText>
                  <TextArea />
                </>
              )}
            </Notes>
          </CardInfoBlock>
        </ScrollLock>

        <ButtonContainer>
          <ActionButton
            type="button"
            variant="secondary"
            onClick={openDeclineApplicantModal}
            size={isMobile ? "fullWidth" : "small"}
          >
            Decline
          </ActionButton>
          <ActionButton
            type="button"
            variant="primary"
            onClick={openAcceptApplicantModal}
            size={isMobile ? "fullWidth" : "small"}
          >
            Accept
          </ActionButton>
        </ButtonContainer>

        <AcceptApplicantModal
          email={email}
          name={name}
          onReview={onReview}
          onClose={closeAcceptApplicantModa}
          onBack={closeAcceptApplicantModa}
          isOpen={isAcceptApplicantModalOpen}
        />
        <DeclineApplicantModal
          onReview={onReview}
          name={name}
          email={email}
          isOpen={isDeclineApplicantModalOpen}
          onClose={closeDeclineApplicantModal}
        />
      </CardContainer>
    </Modal>
  );
};
