import styled from "styled-components";
import { ActionButton } from "../action-button";

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1.5rem;
  width: 95%;
`;

const Button = styled(ActionButton)`
  width: 15%;
  @media (max-width: 800px) {
    width: 100%;
  }
  ${(props) => props.marginLeft && `margin-left: ${props.marginLeft}; `}
`;

export { ButtonContainer, Button };
