import { ChangeEvent, useCallback, useEffect, useState, useRef } from "react";

import { MultiRangeSliderProps } from "./types";
import {
  Label,
  Container,
  Slider,
  SliderTrack,
  SliderRange,
  SliderLeftValue,
  SliderRightValue,
  StyledSlider,
} from "./styles";

export const MultiRangeSlider = ({ min, max, left, right, onChange }: MultiRangeSliderProps) => {
  const [minVal, setMinVal] = useState(left);
  const [maxVal, setMaxVal] = useState(right);
  const minValRef = useRef<HTMLInputElement>(null);
  const maxValRef = useRef<HTMLInputElement>(null);
  const range = useRef<HTMLDivElement>(null);

  // Convert to percentage
  const getPercent = useCallback((value: number) => Math.round(((value - min) / (max - min)) * 100), [min, max]);

  // Set width of the range to decrease from the left side
  useEffect(() => {
    if (maxValRef.current) {
      const minPercent = getPercent(minVal);
      const maxPercent = getPercent(+maxValRef.current.value); // Precede with '+' to convert the value from type string to type number

      if (range.current) {
        range.current.style.left = `${minPercent}%`;
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [minVal, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    if (minValRef.current) {
      const minPercent = getPercent(+minValRef.current.value);
      const maxPercent = getPercent(maxVal);

      if (range.current) {
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [maxVal, getPercent]);

  return (
    <>
      <Label>Age Range</Label>
      <Container>
        <Slider>
          <SliderTrack />
          <SliderRange ref={range}></SliderRange>
          <SliderLeftValue value={minVal}>{minVal}</SliderLeftValue>
          <SliderRightValue value={maxVal}>{maxVal}</SliderRightValue>
        </Slider>
        <StyledSlider
          type="range"
          min={min}
          max={max}
          value={minVal}
          ref={minValRef}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const value = Math.min(+event.target.value, maxVal - 1);
            setMinVal(value);
            event.target.value = value.toString();
            onChange({ min: minVal, max: maxVal });
          }}
        />
        <StyledSlider
          type="range"
          min={min}
          max={max}
          value={maxVal}
          ref={maxValRef}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const value = Math.max(+event.target.value, minVal + 1);
            setMaxVal(value);
            event.target.value = value.toString();
            onChange({ min: minVal, max: maxVal });
          }}
        />
      </Container>
    </>
  );
};
