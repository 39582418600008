import { useMediaQuery } from "react-responsive";
import ScrollLock from "react-scrolllock";

import { device } from "utils/device";
import { ActionButton, Icon } from "components";

import { DirectoryCardDisplayProps } from "./types";
import {
  Address,
  ReqList,
  ReqBlock,
  Location,
  CardDate,
  ReqHeading,
  Description,
  ReqListItem,
  CardHeading,
  CardContainer,
  CardInfoBlock,
  ActionButtonContainer,
} from "./styles";
import { useEffect, useState } from "react";
import { selectPendingOrganizations } from "store/organization/selectors";
import { useTypedSelector } from "store";

export const DirectoryCard = ({
  id,
  tags,
  maxTags,
  name,
  address,
  onApply,
  isModal,
  borough,
  showDate,
  description,
  requirements,
}: DirectoryCardDisplayProps) => {
  const isMobile = useMediaQuery({ query: device.tablet });
  const pendingApplications = useTypedSelector((state) => selectPendingOrganizations(state));
  const [, setHidePending] = useState(false);

  useEffect(() => {
    if (Object.keys(pendingApplications).length) {
      setHidePending(true);
    }

    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  return (
    <CardContainer isModal={isModal}>
      {/* {tags.length > 0 && <Tags tags={tags} maxTags={maxTags} />} */}

      <ScrollLock isActive={false}>
        <CardInfoBlock isModal={isModal}>
          <CardDate>{borough}</CardDate>
          <CardHeading>{name}</CardHeading>
          <Location>
            <Icon icon="location" />
            <Address>{address}</Address>
          </Location>
          <Description isModal={isModal}>{description}</Description>
          <ReqBlock>
            <ReqHeading>Requirements</ReqHeading>
            <ReqList>
              {requirements ? <ReqListItem key={`${id}+${requirements}`}>{requirements}</ReqListItem> : "None"}
            </ReqList>
          </ReqBlock>
        </CardInfoBlock>
      </ScrollLock>

      {!pendingApplications[id] ? (
        <ActionButtonContainer isModal={isModal}>
          <ActionButton type="button" variant="primary" onClick={onApply} size={isMobile ? "fullWidth" : "small"}>
            Apply
          </ActionButton>
        </ActionButtonContainer>
      ) : (
        <ActionButtonContainer isModal={isModal}>
          <ActionButton type="button" variant="secondary" onClick={() => {}} size={isMobile ? "fullWidth" : "small"}>
            Pending...
          </ActionButton>
        </ActionButtonContainer>
      )}
    </CardContainer>
  );
};
