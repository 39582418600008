import styled from "styled-components";

import { device } from "utils/device";

export const DesktopButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledBackButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  border-radius: 4px;
  margin-right: 24px;
  background-color: ${(p) => p.theme.colors.secondaryBlue};
`;

export const MobileBackButton = styled.button`
  height: 32px;

  display: flex;
  flex-direction: row;
  align-items: center;

  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: ${(p) => p.theme.colors.textPrimary};

  .back-icon {
    margin-right: 10px;
  }
`;

export const PageName = styled.h1`
  font-size: 32px;
  font-weight: 800;
  line-height: 40px;

  @media ${device.tablet} {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  }
`;
