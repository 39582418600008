import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type State = {
  id: string;
  email: string;
  race: string;
  sexuality: string;
  gender: string;
  dob: string;
  firstName: string;
  lastName: string;
  priorAcademics: {
    level: string;
    school: string;
    department: string;
    specialization: string;
    yearOfGrad: string;
  };
};

const userInitialState: State = {
  id: "",
  email: "",
  race: "",
  sexuality: "",
  gender: "",
  dob: "",
  firstName: "",
  lastName: "",
  priorAcademics: {
    level: "",
    school: "",
    department: "",
    specialization: "",
    yearOfGrad: "",
  },
};

const userSlice = createSlice({
  name: "user",
  initialState: userInitialState,
  reducers: {
    updateDetails(state, action: PayloadAction<typeof userInitialState>) {
      state = { ...state, ...action.payload };

      return state;
    },
  },
});

const {
  reducer: userReducer,
  actions: { updateDetails },
} = userSlice;

export type UserState = typeof userInitialState;
export { updateDetails, userReducer };
