import { useMemo } from "react";
import ReactSelect from "react-select";

import { useStyles } from "./helpers";
import { SelectLabel } from "./styles";
import { DropdownIndicator } from "./dropdown-indicator";
import { ClearIndicator } from "./clear-indicator";

import { SelectProps } from "../types";
import { LabelText, SelectErrorText } from "../styles";

export const DesktopSelect = ({ label, error, options, isError, onChange, value, ...props }: SelectProps) => {
  const styles = useStyles();

  const components = useMemo(
    () => ({
      IndicatorSeparator: null,
      DropdownIndicator,
      ClearIndicator,
    }),
    [],
  );

  const valueLabel = options?.find((option) => option.value === value?.value) ?? null;

  return (
    <SelectLabel>
      <LabelText>{label}</LabelText>
      <ReactSelect
        styles={styles}
        options={options}
        maxMenuHeight={336}
        isSearchable={false}
        components={components}
        onChange={onChange}
        value={valueLabel}
        {...props}
      />
      {isError && <SelectErrorText>{error}</SelectErrorText>}
    </SelectLabel>
  );
};
