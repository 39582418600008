import React, { ButtonHTMLAttributes, CSSProperties } from "react";
import styled from "styled-components";

import { IconType, Icon } from "../icon";

interface StyledIconButtonProps {
  width?: string;
  display?: string;
  border?: string;
  borderRadius?: string;
  borderColor?: string;
  backgroundColor?: string;
}

interface IconButtonProps extends StyledIconButtonProps, ButtonHTMLAttributes<HTMLButtonElement> {
  icon: keyof typeof IconType;
  width?: string;
  display?: string;
  border?: string;
  borderRadius?: string;
  borderColor?: string;
  backgroundColor?: string;
  iconProps?: CSSProperties;
  iconButtonCssProps?: CSSProperties;
  text?: string;
  color?: string;
}

const StyledButton = styled.button<StyledIconButtonProps>`
  line-height: 0;
  width: ${(props) => props?.width};
  height: ${(props) => props?.width};

  border: ${(props) => props?.border || "0px"};
  border-radius: ${(props) => props?.borderRadius || "0px"};
  border-color: ${(props) => props?.borderColor || "0px"};

  background-color: ${(props) => props?.backgroundColor || "none"};

  display: ${(props) => props?.display || "flex"};
  gap: 5px;
  justify-content: center;
  align-items: center;
`;

export const IconButton = React.forwardRef(
  ({ icon, text, width, iconProps, iconButtonCssProps, color, ...buttonProps }: IconButtonProps, ref) => {
    return (
      <StyledButton style={{ ...iconButtonCssProps }} {...buttonProps} width={width} ref={ref as any}>
        <Icon styles={{ ...iconProps }} icon={icon} />
        {/* same color as icon SVG stroke */}
        {text && <p style={{ color: color || "#7791aa" }}>{text}</p>}
      </StyledButton>
    );
  },
);
