import React from "react";
import { StepperProps } from "react-form-stepper/dist/components/Stepper/StepperTypes";
import { useTheme } from "styled-components";

import { defaultStepperCircleDiameter, StyledFormStepper } from "./styles";

export const Stepper: React.FC<StepperProps> = (props) => {
  const theme = useTheme();

  const stepperCircleDiameter = props.styleConfig?.size ?? defaultStepperCircleDiameter;

  return (
    <StyledFormStepper
      connectorStyleConfig={{
        completedColor: theme.colors.blue,
        disabledColor: theme.colors.greyPlaceholder,
        activeColor: theme.colors.blue,
        size: 2,
        style: "solid",
        stepSize: "2em",
      }}
      styleConfig={{
        activeBgColor: "transparent",
        activeTextColor: theme.colors.blue,
        completedBgColor: theme.colors.blue,
        completedTextColor: theme.colors.white,
        inactiveBgColor: "transparent",
        inactiveTextColor: theme.colors.greyPlaceholder,
        size: stepperCircleDiameter,
        circleFontSize: "1rem",
        labelFontSize: "0.875rem",
        borderRadius: "50%",
        fontWeight: "500",
      }}
      connectorStateColors
      steps={props.steps}
      activeStep={props.activeStep}
    />
  );
};
