import { ROUTES } from "config";
import { ReactComponent as HomeSvg } from "assets/icons/home.svg";
import { ReactComponent as BookSvg } from "assets/icons/book.svg";
import { ReactComponent as HeartSvg } from "assets/icons/heart.svg";
import { ReactComponent as HelpSvg } from "assets/icons/help-circle.svg";
import { ReactComponent as BriefcaseSvg } from "assets/icons/briefcase.svg";

export const iconClassName = "card-icon";

export const cards = [
  {
    icon: <HomeSvg className={iconClassName} />,
    to: ROUTES.HousingSupport,
    heading: "Housing support",
    text: `Shelter, transitional housing programs, and resources for those experiencing housing instability.`,
  },
  {
    icon: <BookSvg className={iconClassName} />,
    to: ROUTES.AcademicDevelopment,
    heading: "Academic development",
    text: `Programs and resources for teens and young adults to further their education in a variety of disciplines.`,
  },
  {
    icon: <HeartSvg className={iconClassName} />,
    to: ROUTES.MentalWellness,
    heading: "Mental wellness",
    text: `Programs and resources for those seeking counseling, substance abuse recovery, psychological treatment, and more.`,
  },
  {
    icon: <BriefcaseSvg className={iconClassName} />,
    to: ROUTES.CareerDevelopment,
    heading: "Career development",
    text: `Skill development, employment readiness, and workforce training programs available for youth.`,
  },
  {
    icon: <HelpSvg className={iconClassName} />,
    to: ROUTES.Faqs,
    heading: "FAQs",
    text: `Frequently Asked Questions`,
  },
];
