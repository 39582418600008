export const organizationStatusEnum = {
  PENDING: "PENDING",
  DECLINED: "DECLINED",
  ACCEPTED: "ACCEPTED",
};

export const applicantStatusEnum = {
  PENDING: "pending",
  ACCEPTED: "accepted",
  DECLINED: "declined",
};
