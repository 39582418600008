import ScrollLock from "react-scrolllock";
import { Icon } from "components";

import { Address, Location, CardHeading, CardInfoBlock } from "components/support-card/styles";
import { OrganizationContainer } from "./styles";
import { CardText } from "components/applicant-card/styles";
import { ClaimOrganizationProps } from "./types";

const SHOW_MORE_LIMIT = 150;

export const ClaimOrganization = ({ org, isSelected }: ClaimOrganizationProps) => {
  return (
    <OrganizationContainer isModal={false} variant={isSelected ? "active" : "default"}>
      <ScrollLock isActive={false}>
        <CardInfoBlock isModal={false}>
          <CardHeading>{org.name}</CardHeading>
          <Location>
            <Icon icon="location" />
            <Address>{org.address}</Address>
          </Location>
          <CardText>
            {`${org.description?.substring(0, SHOW_MORE_LIMIT)}`}{" "}
            {org.description && org.description?.length > SHOW_MORE_LIMIT && "..."}
          </CardText>
        </CardInfoBlock>
      </ScrollLock>
    </OrganizationContainer>
  );
};
