import styled from "styled-components";

import { device } from "utils/device";
import authBg from "assets/images/YourFightismyFight.jpeg";

export const PageContainer = styled.div`
  display: flex;
  height: 100%;
`;

export const LeftSide = styled.div`
  width: 55vw;
  padding: 40px;
  background-color: ${(p) => p.theme.colors.layoutBg};

  @media ${device.tablet} {
    width: 100%;
    height: 100%;
    padding: 0;
  }
`;

export const RightSide = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  width: 45vw;
  padding: 0 80px;
  background-color: ${(p) => p.theme.colors.mainBg};

  @media ${device.tablet} {
    padding: 0;
    width: 100%;
    flex-grow: 0;
    border-radius: 24px;
  }
`;

export const BgImageBlock = styled.div`
  position: relative;
  height: calc(100vh - 80px);
  border-radius: 24px;
  overflow: hidden;
  background: linear-gradient(0deg, #000, rgba(0, 0, 0, 0) 32.84%), url(${authBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;

  @media ${device.tablet} {
    width: 100%;
    height: 100%;
    border-radius: 0;
    padding: 24px 16px;

    overflow: scroll;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const FormBlock = styled.div`
  width: 480px;
  padding: 40px;
  border-radius: 8px;
  border: 1px solid ${(p) => p.theme.colors.border.default};
  box-shadow: 0px 12px 56px 0px rgba(109, 162, 223, 0.16);

  @media ${device.tablet} {
    width: 100%;
    height: 100%;
    padding: 24px 16px;
    background-color: ${(p) => p.theme.colors.mainBg};
  }
`;
