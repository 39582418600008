import { Formik } from "formik";
import { FormikInput, MultiRangeSlider, FormikSelect } from "components";
import { Container, LargeBox, StyledForm } from "./styles";
import { OrganizationDetailsProps } from "./types";
// import { useState } from "react";
import { useCallback, useEffect } from "react";
import { FormikMultiSelect } from "components/formik-multi-select";
import { detailsValidationSchema } from "./config";
import { inPersonOptions, boroughOptions, resourceCategoryOptions, targetCommunityOptions } from "types";
import { RequirementsInput } from "components/requirements-input";
import { AddressSearchBar } from "components/address-search-bar";
import { AddressObject } from "components/address-search-bar/types";
import { Label } from "components/age-range-slider/styles";

export const OrganizationDetailsForm = ({
  setForm,
  setFormId,
  nextClick,
  details,
  contact,
}: OrganizationDetailsProps) => {
  useEffect(() => {
    setFormId("details");
  }, [setFormId]);

  const filteredOptions = resourceCategoryOptions.filter((option) => option.value !== contact.orgType);

  const handleAddressSearch = useCallback(
    (fullAddress: AddressObject, setFieldValue: (field: string, value: any) => void) => {
      setFieldValue("address", fullAddress.address);
      setFieldValue("lat", fullAddress.latLong.lat);
      setFieldValue("long", fullAddress.latLong.lng);
    },
    [],
  );

  return (
    <Container>
      <Formik
        initialValues={details}
        validationSchema={detailsValidationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values) => {
          setForm(values);
          nextClick();
        }}
      >
        {({ values, setFieldValue, errors }) => (
          <StyledForm id="details">
            <FormikSelect
              name="inPerson"
              label="Does your organization offer in-person resources?"
              placeholder="Choose option"
              options={inPersonOptions}
            />
            <FormikSelect
              name="borough"
              label="Borough"
              placeholder="Choose borough"
              options={boroughOptions}
              isDisabled={values.inPerson === "no"}
            />
            <Label>Street Address</Label>
            <AddressSearchBar
              onSubmit={(fullAddress) => handleAddressSearch(fullAddress, setFieldValue)}
              initialValue={values.address}
              isDisabled={values.inPerson === "no"}
              error={errors.address}
            />
            <MultiRangeSlider
              min={0}
              max={100}
              left={details.ageRange.min}
              right={details.ageRange.max}
              onChange={(range) => {
                setFieldValue("ageRange", range);
              }}
            />
            <FormikMultiSelect
              label="Target Communities"
              name="targetCommunities"
              placeholder="Choose target communities"
              options={targetCommunityOptions}
            />
            <FormikMultiSelect
              label="Additional Resources"
              name="additionalResources"
              placeholder="Choose additional resources"
              options={filteredOptions}
            />
            <FormikInput as={LargeBox} name="description" label="Description" type="text" placeholder="Description" />
            <RequirementsInput requirements={values.requirements} />
            <FormikInput name="link" label="Link (optional)" type="text" placeholder="Link" />
          </StyledForm>
        )}
      </Formik>
    </Container>
  );
};
