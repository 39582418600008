import { Icon } from "components";

import { ButtonProps } from "./types";
import { loaderClassName } from "./config";
import { StyledButton, IconWrap } from "./styles";

export const ActionButton = ({
  marginRight,
  display,
  size,
  icon,
  variant,
  children,
  isLoading,
  ...props
}: ButtonProps) => {
  return (
    <StyledButton
      marginRight={marginRight}
      display={display}
      size={size}
      variant={variant}
      isLoading={isLoading}
      {...props}
    >
      {isLoading ? (
        <Icon icon="loader" className={loaderClassName} />
      ) : (
        <>
          {icon && <IconWrap>{icon}</IconWrap>}
          {children}
        </>
      )}
    </StyledButton>
  );
};
