import React from "react";
import ReactJSPopup from "reactjs-popup";
import { PopupProps as ReactJSPopupProps } from "reactjs-popup/dist/types";

interface PopupProps extends ReactJSPopupProps {}

export const Popup: React.FC<PopupProps> = ({ children, trigger, ...props }) => {
  return (
    <ReactJSPopup trigger={trigger} closeOnDocumentClick={true} closeOnEscape={false} offsetY={10} {...props}>
      {(close: React.MouseEventHandler<HTMLDivElement> | undefined) => <div onClick={close}>{children}</div>}
    </ReactJSPopup>
  );
};
