import {
  AgeGroupEnum,
  ageGroupsAssets,
  DistanceEnum,
  distanceAssets,
  FilterCategoryEnum,
  LatLng,
  LocationTypeEnum,
  MAX_AGE,
  MIN_AGE,
  Organization,
  ValueOf,
  PublicBathroom,
  ViewPreferenceEnum,
  ResourceCategoryEnum,
  OrganizationStatusEnum,
} from "types";
import { arrayContainsAny, calculateDistanceByCoord, containsSubstring } from "utils/helpers";
import { FilterName, MapFilters } from "./types";
import { MapState } from "store/map/slice";

export const MANHATTAN_LAT_LNG = { lat: 40.779605431827726, lng: -73.95771542723284 };

/**
 *  Choose whether to use specified location, current location, or default location
 */
export const relevantLocation = (state: MapState) =>
  state.specifiedLocation ?? state.currentLocation ?? MANHATTAN_LAT_LNG;

/**
 *  Use enum definition to initialize filters as unselected (false)
 */
export function initializeFilterFromEnum<T extends Record<string, any>>(e: T): Record<ValueOf<T>, boolean> {
  return Object.values(e).reduce((prev, curr) => ({ ...prev, [curr]: false }), {});
}

/**
 * For each filter category get only the filters that are selected (true)
 */
export const getSelectedFilters = (filters: MapFilters) =>
  (Object.keys(filters) as FilterCategoryEnum[]).reduce((prev, curr) => {
    const filterSection = filters[curr] as Record<string, boolean>;
    const selected = Object.keys(filterSection).filter((filterName) => filterSection[filterName]);
    return { ...prev, [curr]: selected };
  }, {}) as Record<FilterCategoryEnum, FilterName[]>;

/**
 * Filter orgs by resource, community, location, distance, search query
 */
export const filterOrgs = (
  originalOrgs: Organization[],
  originalBathrooms: PublicBathroom[],
  filters: MapFilters,
  searchQuery: string,
  userCoords: LatLng,
) => {
  const selectedFilters = getSelectedFilters(filters);

  // convert bathroom to org
  const bathroomsAsOrgs = originalBathrooms.map((bathroom) => ({
    id: bathroom.id,
    viewPreference: ViewPreferenceEnum.OUTREACH,
    name: bathroom.name,
    orgType: ResourceCategoryEnum.PUBLIC_RESTROOMS,
    email: "",
    contactNumber: "",
    password: "",
    inPerson: true,
    borough: bathroom.borough,
    address: bathroom.address,
    ageLower: MIN_AGE,
    ageUpper: MAX_AGE,
    targetCommunities: [],
    additionalResources: [],
    description: bathroom.operationTime,
    requirements: bathroom.facilityType,
    verification: {
      type: "",
      number: "",
    },
    lat: bathroom.lat,
    long: bathroom.long,
    status: OrganizationStatusEnum.ACCEPTED,
  })) as Organization[];

  const combinedOrgs = [...originalOrgs, ...bathroomsAsOrgs];

  const resourceFilters = selectedFilters[FilterCategoryEnum.RESOURCES_OFFERED];
  const targetCommunitiesFilters = selectedFilters[FilterCategoryEnum.TARGET_COMMUNITIES];
  const ageGroupsFilters = selectedFilters[FilterCategoryEnum.AGE_GROUPS];
  const distanceFilters = selectedFilters[FilterCategoryEnum.DISTANCE] as DistanceEnum[];
  const locationTypeFilters = selectedFilters[FilterCategoryEnum.LOCATION_TYPE] as LocationTypeEnum[];

  // Filtering criterias
  const resourceCriteria = (org: Organization) =>
    resourceFilters.length === 0 ||
    resourceFilters.includes(org.orgType as FilterName) ||
    arrayContainsAny(resourceFilters, org.additionalResources);

  const communityCritera = (org: Organization) =>
    targetCommunitiesFilters.length === 0 || arrayContainsAny(targetCommunitiesFilters, org.targetCommunities);

  const ageCriteria = (org: Organization) =>
    ageGroupsFilters.length === 0 ||
    arrayContainsAny(convertAgeRangeToAgeFilters(org.ageLower, org.ageUpper), ageGroupsFilters);

  const distanceCriteria = (org: Organization) =>
    distanceFilters.length === 0 ||
    org.lat === undefined ||
    org.long === undefined ||
    calculateDistanceByCoord({ lat: org.lat, lng: org.long }, userCoords) <=
      Math.max(...distanceFilters.map((d) => distanceAssets[d].distance));

  const locationCriteria = (org: Organization) =>
    locationTypeFilters.length === 0 ||
    org.inPerson === undefined ||
    (org.inPerson && locationTypeFilters.includes(LocationTypeEnum.IN_PERSON)) ||
    (!org.inPerson && locationTypeFilters.includes(LocationTypeEnum.REMOTE));

  const searchCritera = (org: Organization) => searchQuery === "" || containsSubstring(org.name, searchQuery);

  // Apply filters and sort
  return combinedOrgs
    .filter(resourceCriteria)
    .filter(communityCritera)
    .filter(ageCriteria)
    .filter(distanceCriteria)
    .filter(locationCriteria)
    .filter(searchCritera)
    .sort((a, b) => compareOrgDistance(a, b, userCoords));
};

/**
 * Sort orgs in ascending order
 *
 * Remote orgs are returned last
 */
const compareOrgDistance = (a: Organization, b: Organization, userCoords: LatLng) => {
  if (!a.lat || !a.long) {
    return 1;
  }

  if (!b.lat || !b.long) {
    return -1;
  }

  return (
    calculateDistanceByCoord({ lat: a.lat, lng: a.long }, userCoords) -
    calculateDistanceByCoord({ lat: b.lat, lng: b.long }, userCoords)
  );
};

/**
 *  Return all age range filters that correspond to an age range
 *
 *  Ex. (16, 22) -> teen, youngAdult
 */
const convertAgeRangeToAgeFilters = (lower: number = MIN_AGE, upper: number = MAX_AGE) =>
  (Object.keys(ageGroupsAssets) as AgeGroupEnum[]).filter((name) => {
    const { min, max } = ageGroupsAssets[name];
    return (
      (min >= lower && min <= upper) ||
      (max <= upper && max >= lower) ||
      (min <= lower && max >= upper) ||
      (min >= lower && max <= upper)
    );
  });
