import { FieldHookConfig, useField } from "formik";

import { MultiSelect } from "components";
import { SelectProps } from "components/multi-select/types";

export const FormikMultiSelect = ({ label, ...props }: SelectProps & FieldHookConfig<string>) => {
  const [field, meta, helpers] = useField(props);
  const isError = Boolean(meta.error) && meta.touched;

  return (
    <MultiSelect
      {...props}
      // @ts-ignore
      isMulti={true}
      label={label}
      name={field.name}
      isError={isError}
      error={meta.error}
      //@ts-ignore
      onChange={(options) => {
        // @ts-ignore
        options && helpers.setValue(options.map((option) => option.value));
      }}
      //@ts-ignore
      value={field.value}
    />
  );
};
