import * as Yup from "yup";

export const detailsValidationSchema = Yup.object().shape({
  inPerson: Yup.string()
    .default(() => "")
    .required("In Person is a required field"),
  borough: Yup.string().when("inPerson", {
    is: "yes",
    then: Yup.string()
      .default(() => "")
      .required("Borough is a required field"),
  }),
  address: Yup.string().when("inPerson", {
    is: "yes",
    then: Yup.string()
      .default(() => "")
      .required("Street Address is a required field"),
  }),
  description: Yup.string()
    .default(() => "")
    .required("Description is a required field"),
});

export const detailsInitialValues = detailsValidationSchema.cast({});
