import styled from "styled-components";
import { device } from "utils/device";

export const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  height: 56px;
  width: 100%;
  margin-bottom: 16px;
  justify-content: space-between;
  gap: 20px;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  position: relative;
`;

export const SearchToolsBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 8;
  column-gap: 40px;

  @media ${device.tablet} {
    max-width: 100%;
  }
`;
