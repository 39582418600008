import styled, { css } from "styled-components";
import { Popup } from "components/popup";

export const SortOption = styled.div<{ selected: Boolean }>`
  cursor: pointer;
  color: ${(p) => p.theme.colors.textPrimary};
  font-weight: 500;
  padding: 20px 15px;

  &:hover {
    color: ${(p) => p.theme.colors.blue};
    font-weight: 700;
  }
  ${(props) =>
    props.selected &&
    css`
      color: ${props.theme.colors.blue};
    `}
`;

export const StyledPopup = styled(Popup)`
  &-content {
    width: 200px;
    background-color: white;
    border-radius: 8px;
  }
`;
