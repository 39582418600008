import styled, { css } from "styled-components";
import { device } from "utils/device";
import { StyledStatusProps } from "./types";

export const StyledStatus = styled.span<StyledStatusProps>`
  display: inline-block;
  padding: 4px 16px;
  border-radius: 4px;
  text-transform: capitalize;

  ${(p) => css`
    color: ${p.theme.colors.status[p.type]?.color};
    background-color: ${p.theme.colors.status[p.type]?.bg};
  `}
`;

export const StatsBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  min-height: 80px;
  padding: 0 24px;
  margin-bottom: 24px;
  border-radius: 12px;
  border: 1px solid ${(p) => p.theme.colors.border.default};
  box-shadow: 0px 12px 56px 0px rgba(109, 162, 223, 0.16);

  font-size: 16px;
  line-height: 24px;
  font-weight: 700;

  @media ${device.tablet} {
    padding: 16px;
    margin-bottom: 16px;
    flex-direction: column;
  }
`;

export const StatsItem = styled.span`
  display: inline-block;

  @media ${device.tablet} {
    margin-bottom: 16px;
  }

  ${(p) => css`
    color: ${p.theme.colors.textSecondary};

    :first-child {
      margin-right: auto;
    }

    strong {
      color: ${p.theme.colors.textPrimary};
    }
  `}
`;

export const Statuses = styled.div`
  display: flex;
  column-gap: 16px;
  padding-left: 24px;

  @media ${device.tablet} {
    width: 100%;
    row-gap: 8px;
    padding-left: 0;
    flex-direction: column;
  }
`;
