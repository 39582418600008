import React from "react";
import GooglePlacesAutocomplete, { getLatLng, geocodeByPlaceId } from "react-google-places-autocomplete";
import { SearchIcon, Container, ErrorText } from "./styles";
import { Icon } from "components";
import { LatLong, AddressSearchBarProps } from "./types";
import { COLORS } from "config/styles/colors";

export const AddressSearchBar = ({ onSubmit, initialValue, isDisabled, error }: AddressSearchBarProps) => {
  const getAddressObject = (address_components: google.maps.GeocoderAddressComponent[]) => {
    const AddressComponentMapper: { [key: string]: string[] } = {
      street_number: ["street_number"],
      postal_code: ["postal_code"],
      street: ["street_address", "route"],
      province: [
        "administrative_area_level_1",
        "administrative_area_level_2",
        "administrative_area_level_3",
        "administrative_area_level_4",
        "administrative_area_level_5",
      ],
      city: [
        "locality",
        "sublocality",
        "sublocality_level_1",
        "sublocality_level_2",
        "sublocality_level_3",
        "sublocality_level_4",
      ],
      country: ["country"],
    };

    let address: { [key: string]: string } = {
      street_number: "",
      postal_code: "",
      street: "",
      province: "",
      city: "",
      country: "",
    };

    address_components.forEach((component) => {
      for (const expectedAddressComponent in AddressComponentMapper) {
        if (AddressComponentMapper[expectedAddressComponent].indexOf(component.types[0]) !== -1) {
          if (expectedAddressComponent === "country") {
            address[expectedAddressComponent] = component.short_name;
          } else {
            address[expectedAddressComponent] = component.long_name;
          }
        }
      }
    });

    address_components.forEach((component) => {
      for (const expectedAddressComponent in AddressComponentMapper) {
        if (AddressComponentMapper[expectedAddressComponent].indexOf(component.types[0]) !== -1) {
          if (expectedAddressComponent === "country") {
            address[expectedAddressComponent] = component.short_name;
          } else {
            address[expectedAddressComponent] = component.long_name;
          }
        }
      }
    });

    address.address = address.city + ", " + address.province + ", " + address.country;
    const fullStreetAddress = address.street_number + " " + address.street;
    if (fullStreetAddress.trim() !== "") {
      address.address = fullStreetAddress + ", " + address.address;
    }

    delete address.street_number;
    delete address.street;

    if (address.country === "US") {
      address.state = address.province;
      delete address.province;
    }
    return address;
  };

  const saveAddress = async (tempAddress: any) => {
    const initialLatLong: LatLong = { lat: 0, lng: 0 };

    const geocodeObj = tempAddress && tempAddress?.value && (await geocodeByPlaceId(tempAddress.value.place_id));
    const addressObject = geocodeObj && getAddressObject(geocodeObj[0].address_components);
    const userLatLong = geocodeObj && (await getLatLng(geocodeObj[0]));
    const newFullAddress = {
      postal_code: "",
      city: "",
      country: "",
      address: "",
      state: "",
      latLong: initialLatLong,
    };

    newFullAddress.postal_code = addressObject?.postal_code ?? "";
    newFullAddress.city = addressObject?.city ?? "";
    newFullAddress.country = addressObject?.country ?? "";
    newFullAddress.address = addressObject?.address ?? "";
    newFullAddress.state = addressObject?.state ?? "";
    newFullAddress.latLong = userLatLong ?? initialLatLong;

    onSubmit(newFullAddress); // setting the user selected address
  };

  const filterOptions = (option: any, inputValue: any) => {
    if (inputValue) {
      return option.label.toLowerCase().includes(inputValue.toLowerCase());
    }
    return true;
  };
  // const options = [ // keep for later figuring out how to add options
  //   { label: "Current Location", value: "currentLocation" }, // Add "Current Location" as the first option
  //   // Add other options here
  // ];
  return (
    <Container>
      <GooglePlacesAutocomplete
        selectProps={{
          isDisabled: isDisabled,
          defaultInputValue: initialValue,
          isClearable: true,
          onChange: saveAddress,
          placeholder: "Enter location",
          filterOption: filterOptions,
          styles: {
            control: (provided, state) => ({
              ...provided,
              cursor: "text",
              "&:select": {
                borderColor: COLORS.white,
              },
              height: "56px",
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "24px",
              borderRadius: "8px",
              outline: "none",
              borderBottom: `2px solid ${COLORS.blue}`,
              border: `1px solid ${COLORS.border.default}`,
            }),
            indicatorSeparator: () => ({
              display: "none",
            }),
            dropdownIndicator: () => ({
              display: "none",
            }),
            option: (provided, state) => ({
              ...provided,
              color: state.isFocused ? COLORS.blue : COLORS.textPrimary,
              padding: "12px",
              backgroundColor: COLORS.mainBg,
              "& em": {
                color: COLORS.blue,
                fontWeight: "normal",
                textDecoration: "underline",
              },
            }),
            input: (provided) => ({
              ...provided,
              marginLeft: "35px",
            }),
            placeholder: (provided) => ({
              ...provided,
              marginLeft: "35px",
              color: COLORS.greyPlaceholder,
              fontWeight: "unset",
            }),
            singleValue: (provided) => ({
              ...provided,
              marginLeft: "35px",
            }),
          },
        }}
      />
      <SearchIcon>
        <Icon icon="location-pin-search" />
      </SearchIcon>
      {error && <ErrorText>{error}</ErrorText>}
    </Container>
  );
};
