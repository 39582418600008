import styled, { css } from "styled-components";

import { loaderRotateAnimation } from "utils/styles";

import { StyledButtonProps } from "./types";
import { loaderClassName, sizes } from "./config";

export const IconWrap = styled.div`
  line-height: 0;
`;

export const StyledButton = styled.button<StyledButtonProps>`
  grid-column-gap: 8px;
  padding: 0 16px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;

  display: ${(props) => props?.display || "flex"};
  margin-right: ${(props) => props?.marginRight || "none"};

  align-items: center;

  ${(p) => css`
    height: ${p.height || "56px"};
    justify-content: ${p.justifyContent || "center"};

    width: ${sizes[p.size]};
    color: ${p.theme.colors.button[p.variant].color};
    background-color: ${p.theme.colors.button[p.variant].bg.default};
    ${p.variant === "secondary" && `border: 1px solid ${p.theme.colors.button[p.variant].color};`}
    border-radius: ${p.borderRadius || "8px"};
    transition: ${p.theme.transition};

    ${IconWrap} path {
      transition: ${p.theme.transition};
      fill: ${p.theme.colors.button[p.variant].bg.default};
      stroke: ${p.theme.colors.button[p.variant].color};
    }

    :hover {
      background-color: ${p.theme.colors.button[p.variant].bg.hover};
      ${IconWrap} path {
        fill: ${p.theme.colors.button[p.variant].bg.hover};
      }
    }

    :active {
      background-color: ${p.theme.colors.button[p.variant].bg.active};
      ${IconWrap} path {
        fill: ${p.theme.colors.button[p.variant].bg.active};
      }
    }

    :disabled {
      cursor: default;
      opacity: ${p.isLoading ? "1" : "0.4"};
      color: ${p.theme.colors.button[p.variant].color};
      background-color: ${p.theme.colors.button[p.variant].bg.default};
    }

    .${loaderClassName} {
      animation: ${loaderRotateAnimation} 1s linear infinite;
      path {
        fill: ${p.theme.colors.button.primary.color};
      }
    }
  `}
`;
