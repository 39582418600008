import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface authInitialState {
  session: boolean;
  token?: string;
}

const initialState: authInitialState = {
  session: false,
  token: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    storeSession(state, action: PayloadAction<string>) {
      state.session = true;
      state.token = action.payload;

      return state;
    },
  },
});

const {
  reducer: authReducer,
  actions: { storeSession },
} = authSlice;

export type AuthState = typeof initialState;
export { storeSession, authReducer, initialState };
