import styled from "styled-components";

export const MobileSelectContainer = styled.div`
  width: 100%;
  position: relative;
  padding-bottom: 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

interface StyledMobilesSelectProps {
  withValue: boolean;
  selectVisible: boolean;
}

export const StyledMobileSelect = styled.div<StyledMobilesSelectProps>`
  width: 100%;
  height: 56px;
  padding: 0 16px;
  border-radius: 8px;

  border: 1px solid ${(p) => (p.selectVisible ? "#4B9CE6" : "#eceeef")};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: ${(p) => (p.withValue ? "#0F202F" : "#9fa9b3")};

  .chevron-icon {
    path {
      fill: #4b9ce6;
    }
    transform: ${(p) => (p.selectVisible ? "rotate(180deg)" : "none")};
  }
`;

export const FixedContainer = styled.div`
  inset: 0;
  z-index: 1000;
  position: fixed;
  background-color: rgba(15, 32, 47, 0.4);

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const OptionsContainer = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 8px 8px 0px 0px;

  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
`;

export const ContentContainer = styled.div``;

export const StyledOption = styled.button`
  width: 100%;
  height: 56px;
  padding: 0 16px;
  -webkit-tap-highlight-color: transparent;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: ${(p) => p.theme.colors.textPrimary};

  :active {
    color: #4b9ce6;
    background-color: #f6faff;
  }
`;

export const ActionButtonContainer = styled.div`
  width: 100%;
  padding: 0 16px;
  align-self: flex-end;
  margin-top: 16px;
`;

export const SelectErrorText = styled.span`
  position: absolute;
  left: 0;
  bottom: 0;
  margin-top: 4px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: ${(p) => p.theme.colors.input.color.error};
`;
