import { COLORS } from "config/styles/colors";
import styled, { css } from "styled-components";

import { device } from "utils/device";
import { linkStyles } from "utils/styles";

export const HomePageContainer = styled.main``;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 0 1 calc((100% - 24px) / 2);
  padding: 24px;
  border-radius: 16px;
  box-shadow: 0px 12px 56px 0px rgba(109, 162, 223, 0.16);
  width: 100%;

  ${(p) => css`
    color: ${p.theme.colors.textPrimary};
    border: 1px solid ${p.theme.colors.border.default};
    background-color: ${p.theme.colors.bg.main};
  `}

  @media ${device.tablet} {
    flex: none;
    width: 100%;
  }
`;

export const CardHeading = styled.div`
  line-height: 50px;
  display: inline-block;
  font-size: 24px;
  font-weight: 700;
  vertical-align: middle;
  width: 100%;
`;

export const CardText = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  height: auto;
  display: inline-block;
`;

export const CardEmail = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  vertical-align: middle;
`;

export const ShowMoreBtn = styled.button`
  ${linkStyles}
  color: ${COLORS.blue};
`;

export const IconButtonWrapper = styled.div`
  float: right;
`;

export const CardName = styled.div`
  margin-right: 12px;
  display: inline-block;
`;

export const CardDate = styled.p`
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
  color: ${COLORS.greyPlaceholder};
`;
