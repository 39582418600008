import type { RootState } from "store";
import { Application, Organization } from "types";

export const selectOrgState = (state: RootState) => state.org;

export const selectFilteredOrg = (state: RootState, orgType: string) => {
  const allOrgs = state.org.collection;

  switch (orgType) {
    case "shelter":
    case "mentalWellness":
    case "professionalServices":
    case "academic":
      break;
    default:
      throw new Error("Org type not found");
  }

  //TODO: Modifying this to appease linter but ihni what its supposed to do. figure out!
  const result = allOrgs?.filter((org: Organization) => org.orgType === orgType);

  return result;
};

export const selectPendingOrganizations = (state: RootState) => {
  const userApps = state.application.collection;
  const ids: { [id: string]: boolean } = {};

  userApps.forEach((app: Application) => {
    if (app.status === "pending") ids[app.orgId] = true;
  });

  return ids;
};
