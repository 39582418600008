import { Stepper as FormStepper } from "react-form-stepper";
import styled, { DefaultTheme, ThemedStyledProps } from "styled-components";
import { StepperProps } from "react-form-stepper/dist/components/Stepper/StepperTypes";

export const defaultStepperCircleDiameter = "2em";

const getCircleDiameter = (p: ThemedStyledProps<StepperProps, DefaultTheme>) =>
  p.styleConfig?.size ?? defaultStepperCircleDiameter;

export const StyledFormStepper = styled(FormStepper)<StepperProps>`
  button.active {
    border: 3px solid ${(p) => p.theme.colors.blue};

    &:hover {
      background-color: transparent;
    }
  }

  button:disabled {
    border: 3px solid ${(p) => p.theme.colors.greyPlaceholder};
  }

  button:hover {
    background-color: transparent;
  }

  width: 100%;

  #RFS-ConnectorContainer {
    left: calc(-50% + ${getCircleDiameter} / 2);
    right: calc(50% + ${getCircleDiameter} / 2);
  }
`;
