import styled from "styled-components";
import { device } from "utils/device";

export const QuoteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-row-gap: 24px;
  position: absolute;
  left: 40px;
  bottom: 40px;
  right: 40px;
  color: ${(p) => p.theme.colors.white};

  @media ${device.tablet} {
    top: 16px;
    left: 0px;
    right: 0px;
    position: relative;
  }
`;

export const QuoteText = styled.q`
  display: block;
  font-size: 80px;
  font-weight: 800;
  line-height: 80px;

  @media ${device.tablet} {
    font-size: 40px;
    font-weight: 700;
    line-height: 44px;
  }
`;

export const QuoteAuthor = styled.p`
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;

  @media ${device.tablet} {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
`;

export const QuoteHighlight = styled.span`
  color: ${(p) => p.theme.colors.blue};
`;
