import styled from "styled-components";

const InputLabel = styled.label`
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
`;

const Container = styled.div`
  width: 100%;
  margin-bottom: 5%;
`;

export { InputLabel, Container };
