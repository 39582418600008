import { TagsProps } from "./types";
import { TagsBlock, StyledTag } from "./styles";

export const Tags = ({ tags, maxTags }: TagsProps) => {
  const renderTags = () => {
    if (maxTags) {
      const tagsList: JSX.Element[] = [];
      for (let i = 0; i < Math.min(maxTags, tags.length); i++) {
        tagsList.push(<StyledTag key={tags[i]}>{tags[i]}</StyledTag>);
      }
      if (tags.length > maxTags)
        tagsList.push(<StyledTag key={tags.length}>{`+${tags.length - maxTags} more`}</StyledTag>);

      return tagsList;
    }

    return tags.map((tag) => <StyledTag key={tag}>{tag}</StyledTag>);
  };

  return <TagsBlock>{renderTags()}</TagsBlock>;
};
