import { components, IndicatorComponentType } from "react-select";

import { IsMulti, OptionType } from "../../types";
import { COLORS } from "config/styles/colors";
import styled from "styled-components";

const StyledText = styled.p`
  color: ${COLORS.blue};
`;

export const ClearIndicator: IndicatorComponentType<OptionType, IsMulti> = (props) => (
  <components.ClearIndicator {...props}>
    <StyledText>Clear</StyledText>
  </components.ClearIndicator>
);
