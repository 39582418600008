import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "config";
import { RootState } from "store";

export const authFetchBaseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = (getState() as RootState).auth.token;
    headers.set("Access-Control-Allow-Origin", "*");

    if (token) {
      headers.set("authorization", `${token}`);
    }
    return headers;
  },
  credentials: "include",
});
