import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PublicBathroom } from "types";

type PublicBathroomInitialState = {
  collection: PublicBathroom[] | null;
  count: number;
};

const publicBathroomInitialState: PublicBathroomInitialState = {
  collection: null,
  count: 0,
};

const publicBathroomSlice = createSlice({
  name: "publicBathroom",
  initialState: publicBathroomInitialState,
  reducers: {
    updatePublicBathrooms(state, action: PayloadAction<typeof publicBathroomInitialState>) {
      state = { ...state, ...action.payload };
      return state;
    },
  },
});

const {
  reducer: publicBathroomReducer,
  actions: { updatePublicBathrooms },
} = publicBathroomSlice;

export type UserState = typeof publicBathroomInitialState;
export { updatePublicBathrooms, publicBathroomReducer };
