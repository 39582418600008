import { useMediaQuery } from "react-responsive";

import { device } from "utils/device";
import { ActionButton, IconButton, Modal } from "components";

import { AcceptApplicantModalProps } from "./types";
import { CardHeading, CardContainer, CardInfoBlock, ButtonContainer, CardTitle, SubTitle } from "./styles";
import { useState } from "react";
import { Checkbox } from "components";
import { EmailTag } from "components/email";
import { TextArea } from "components/textarea";
import { useToast } from "hooks/use-toast";

// use only one ReviewRequest type

export const AcceptApplicantModal = ({ name, email, onBack, onClose, isOpen, onReview }: AcceptApplicantModalProps) => {
  const isMobile = useMediaQuery({ query: device.tablet });

  const [messageValue, setMessageValue] = useState("");
  const [toggleCheck, setToggleCheck] = useState(false);
  const { notifyEmail, notifySuccess } = useToast();

  const handleClick = () => {
    onReview({
      message: messageValue,
      userEmail: email,
      userName: name,
      status: "accepted",
      notifyUser: toggleCheck ? "false" : "true",
    });
    !toggleCheck && notifyEmail();
    notifySuccess();
    onClose();
  };

  return (
    <Modal maxWidth="992px" closeModal={onClose} onBackdropClick={onClose} isOpen={isOpen}>
      <CardContainer>
        <CardInfoBlock>
          <CardTitle>
            <IconButton icon="arrow-left" onClick={onBack} />
            &nbsp;
            <CardHeading>Accept Applicant</CardHeading>
          </CardTitle>
          To: {name}
          <EmailTag email={email} />
          <SubTitle>New Message</SubTitle>
          <Checkbox
            label="Applicant has already been contacted."
            checked={toggleCheck}
            onChange={() => setToggleCheck((toggleCheck) => !toggleCheck)}
          />
          <TextArea
            value={messageValue}
            placeholder="This is a custom message."
            withErrorMessage={false}
            onSearchClear={() => setMessageValue("")}
            onChange={(e) => setMessageValue(e.target.value)}
            disabled={toggleCheck}
          />
        </CardInfoBlock>

        <ButtonContainer>
          <ActionButton type="button" variant="secondary" onClick={onClose} size={isMobile ? "fullWidth" : "small"}>
            Cancel
          </ActionButton>
          <ActionButton type="button" variant="primary" size={isMobile ? "fullWidth" : "small"} onClick={handleClick}>
            Confirm
          </ActionButton>
        </ButtonContainer>
      </CardContainer>
    </Modal>
  );
};
