import { EmailAddress, EmailStyle } from "./styles";

import { Icon } from "../icon";
import { EmailProps } from "./types";

export const EmailTag = ({ email }: EmailProps) => {
  return (
    <EmailStyle>
      <Icon icon="message" className="message-icon" />
      <EmailAddress href={`mailto: ${email}`}>{email}</EmailAddress>
    </EmailStyle>
  );
};
