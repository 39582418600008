import styled, { css } from "styled-components";
import { device } from "utils/device";
import { COLORS } from "config/styles/colors";

export const ProfilePageContainer = styled.div`
  padding: 40px;
  border-radius: 16px;
  ${(p) => css`
    border: 1px solid ${p.theme.colors.border.default};
    background-color: ${p.theme.colors.bg.main};
  `}

  @media ${device.tablet} {
    padding: 16px;
  }
`;

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 44px;

  @media ${device.tablet} {
    row-gap: 32px;
  }
`;

export const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;

  @media ${device.tablet} {
    row-gap: 16px;
  }
`;

export const FormSectionHeading = styled.h2`
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: ${(p) => p.theme.colors.textSecondary};

  @media ${device.tablet} {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }
`;

export const FormControlsBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 4px;
  column-gap: 24px;

  @media ${device.tablet} {
    row-gap: 0;
  }
`;

export const FormControl = styled.div`
  flex: 0 1 calc((100% - 48px) / 3);

  @media ${device.tablet} {
    flex: none;
    width: 100%;
  }
`;

export const FormControl2 = styled.div`
  flex: 0 1 calc((100% - 24px) / 2);

  @media ${device.tablet} {
    flex: none;
    width: 100%;
  }
`;

export const ButtonsBlock = styled.div`
  display: flex;
  column-gap: 16px;
  margin-top: 40px;

  @media ${device.tablet} {
    flex-wrap: wrap;
    row-gap: 16px;
    margin-top: 16px;
  }
`;

export const AddReqText = styled.button`
  color: ${COLORS.blue};
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  display: inline;
  padding-bottom: 20px;
`;
