import { FieldHookConfig, useField } from "formik";

import { Select } from "components";
import { SelectProps } from "components/select/types";

export const FormikSelect = ({ label, ...props }: SelectProps & FieldHookConfig<string>) => {
  const [field, meta, helpers] = useField(props);
  const isError = Boolean(meta.error) && meta.touched;

  return (
    <Select
      {...props}
      label={label}
      name={field.name}
      isError={isError}
      error={meta.error}
      selectedValue={field.value}
      value={{ value: field.value, label: field.name }}
      onChange={(option) => option && helpers.setValue(option.value)}
    />
  );
};
