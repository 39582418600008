import toast, { Toaster } from "react-hot-toast";
import { Icon } from "../../components/icon";
import { IconWrapper } from "./styles";

export const useToast = () => {
  const notifySuccess = () =>
    toast.success("Successfully accepted applicant!", {
      icon: (
        <IconWrapper>
          <Icon icon="newcheck" className="check-icon" />
        </IconWrapper>
      ),
      style: {
        borderLeft: "8px solid #34A853",
        padding: "0.7em",
        minWidth: "300px",
      },
    });

  const notifyError = () =>
    toast.error("Successfully declined applicant!", {
      icon: (
        <IconWrapper>
          <Icon icon="close" className="close-icon" />
        </IconWrapper>
      ),
      style: {
        borderLeft: "8px solid #F9604B",
        padding: "0.7em",
        minWidth: "300px",
      },
    });

  const notifyDelete = () =>
    toast.error("Successfully deleted account!", {
      icon: (
        <IconWrapper>
          <Icon icon="close" className="close-icon" />
        </IconWrapper>
      ),
      style: {
        borderLeft: "8px solid #F9604B",
        padding: "0.7em",
        minWidth: "300px",
      },
    });

  const notifyEmail = () =>
    toast("Applicant has been contacted", {
      icon: <Icon icon="message" />,
      style: {
        borderLeft: "8px solid #0F202F",
        padding: "0.7em",
        minWidth: "300px",
      },
    });

  const notifyUpdate = () =>
    toast("Applicant status has been updated", {
      icon: (
        <IconWrapper>
          <Icon icon="newpencil" className="status-icon" />
        </IconWrapper>
      ),
      style: {
        borderLeft: "8px solid #4B9CE6",
        padding: "0.7em",
        minWidth: "300px",
      },
    });

  const notifyApplicationSuccess = () =>
    toast.success("Successfully applied!", {
      icon: (
        <IconWrapper>
          <Icon icon="newcheck" className="check-icon" />
        </IconWrapper>
      ),
      style: {
        borderLeft: "8px solid #34A853",
        padding: "0.7em",
        minWidth: "300px",
      },
    });

  const Toast = <Toaster position="bottom-right" />;

  return { Toast, notifySuccess, notifyError, notifyDelete, notifyEmail, notifyUpdate, notifyApplicationSuccess };
};
