import styled from "styled-components";
import { ActionButton } from "components/action-button";
import { Form } from "formik";

const Container = styled.div`
  align-self: center;
  height: 100%;
  width: 100%;
  padding: 0 20px;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LargeBox = styled.textarea`
  width: 100%;
  height: 150px;
  resize: none;
  padding: 12px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`;

const Button = styled(ActionButton)`
  margin-bottom: 1rem;
`;

const StyledForm = styled(Form)`
  width: 55%;
  align-self: center;
  align-items: center;
`;

export { ButtonContainer, Button, Container, LargeBox, StyledForm };
