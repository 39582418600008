import { lazy } from "react";

import { ROUTES } from "config";

const NotFound = lazy(() => import("pages/not-found"));
const Auth = lazy(() => import("pages/auth"));
const Main = lazy(() => import("pages/main"));
const OrganizationDashboard = lazy(() => import("pages/organization-dashboard"));
const OrganizationSignup = lazy(() => import("pages/organization-signup"));

export const PRIVATE_ROUTES = [
  {
    exact: true,
    path: [
      "/",
      ROUTES.ClearpathMap,
      ROUTES.HousingSupport,
      ROUTES.MentalWellness,
      ROUTES.AcademicDevelopment,
      ROUTES.CareerDevelopment,
      ROUTES.UserProfile,
      ROUTES.Faqs,
      ROUTES.Applications,
    ],
    component: Main,
  },
  {
    exact: true,
    path: [ROUTES.Home],
    component: Main,
  },
];

export const ORGANIZATION_ROUTES = [
  {
    exact: true,
    path: [ROUTES.OrganizationHome, ROUTES.OrganizationProfile, ROUTES.OrganizationStatus],
    component: OrganizationDashboard,
  },
];

export const PUBLIC_ROUTES = [
  {
    exact: true,
    path: [ROUTES.SignUp, ROUTES.SignIn, ROUTES.ResetPassword],
    component: Auth,
  },
  {
    exact: true,
    path: [ROUTES.OrganizationSignup],
    component: OrganizationSignup,
  },
  {
    exact: false,
    path: ROUTES.NotFound,
    component: NotFound,
  },
];
