import { createSlice } from "@reduxjs/toolkit";

type State = {};

const interactionInitialState: State = {};

const interactionSlice = createSlice({
  name: "interaction",
  initialState: interactionInitialState,
  reducers: {},
});

const { reducer: interactionReducer } = interactionSlice;

export { interactionReducer };
