import styled, { css } from "styled-components";

import { device } from "utils/device";
import { linkStyles } from "utils/styles";

export const Absolute = styled.div`
  position: absolute;
  top: 88px;
  left: -63px;
  transform: translateX(-50%);
  z-index: 1000;
  cursor: default;

  @media ${device.tablet} {
    left: 0;
    right: 0;
    top: 56px;
    bottom: 0;
    z-index: 1000;
    position: fixed;
    transform: none;
    background-color: rgba(15, 32, 47, 0.4);
    border-top: 1px solid ${(p) => p.theme.colors.layoutBg};
  }
`;

export const NotificationContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  grid-row-gap: 24px;
  padding: 24px;
  width: 417px;
  border-radius: 8px;
  z-index: 1000;
  box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.2);

  @media ${device.tablet} {
    width: 100%;
    box-shadow: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  ${(p) => css`
    background-color: ${p.theme.colors.bg.main};

    ::before {
      content: "";
      display: block;
      position: absolute;
      top: -12px;
      left: 287px;
      width: 26px;
      height: 26px;
      border-radius: 3px;
      background-color: ${p.theme.colors.bg.main};
      transform: rotate(45deg);
    }

    @media ${device.tablet} {
      ::before {
        display: none;
      }
    }
  `}
`;

export const Count = styled.p`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
  color: ${(p) => p.theme.colors.textSecondary};
`;

export const NotificationsList = styled.ul`
  display: flex;
  flex-direction: column;
  grid-row-gap: 33px;
`;

export const ListItem = styled.li`
  padding-left: 12px;
  text-align: left;
`;

export const NotificationText = styled.p`
  position: relative;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;

  ${(p) => css`
    color: ${p.theme.colors.textPrimary};

    ::before {
      content: "";
      display: block;
      position: absolute;
      left: -11px;
      top: 50%;
      transform: translateY(-50%);
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: ${p.theme.colors.blue};
    }
  `}
`;

export const ShowMore = styled.button`
  ${linkStyles}
`;
