import styled from "styled-components";
import { Link } from "react-router-dom";
import { DropdownItemProps, DropdownMenuProps } from "./types";

export const DropdownMenu = styled.div<DropdownMenuProps>`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 8px;
  filter: ${(div) =>
    div.isMobile ? "drop-shadow(0px 16px 10px rgba(0, 0, 0, 0.1))" : "drop-shadow(0px 16px 40px rgba(0, 0, 0, 0.1))"};
  width: ${(div) => (div.isMobile ? "110vw" : "255px")};
  position: absolute;
  right: ${(div) => (div.isMobile || div.isOrganization ? "0px" : "40px")};
  // left: ${(div) => (div.isMobile ? "100vw" : "auto")};
`;

export const DropdownGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DropdownItem = styled(Link)<DropdownItemProps>`
  display: flex;
  flex-direction: row;
  padding: 20px 20px 16px 20px;
  gap: 12px;
  text-decoration: none;
  color: ${({ isLinkActive }) => (isLinkActive ? "black" : "grey")};
  font-weight: ${({ isLinkActive }) => (isLinkActive ? "bold" : "normal")};
  .dropdown-icon {
    margin-top: -3px;
    path {
      stroke: ${({ isLinkActive }) => (isLinkActive ? "black" : "grey")};
    }
  }
`;
