import { QuoteWrapper, QuoteText, QuoteHighlight } from "./styles";

// TODO: Get random quote from api. Not implemented yet
export const Quote = () => {
  return (
    <QuoteWrapper>
      <QuoteText>
        <QuoteHighlight>Let</QuoteHighlight> Your Fight <QuoteHighlight>Be</QuoteHighlight> Our Fight{" "}
        <QuoteHighlight>Too!</QuoteHighlight>
      </QuoteText>
    </QuoteWrapper>
  );
};
