import { StatsBlock, StatsItem, Statuses, StyledStatus } from "./styles";
import { StatusType, UserStatsProps, UserAppStatsProps } from "./types";
import { StatusTypeEnum } from "types";

export const Status = ({ type, count }: { type: string; count: number }) => {
  let convertedType: StatusType = "pending";

  switch (type) {
    case StatusTypeEnum.PENDING:
      convertedType = StatusTypeEnum.PENDING;
      break;
    case StatusTypeEnum.DECLINED:
      convertedType = StatusTypeEnum.DECLINED;
      break;
    case StatusTypeEnum.COMPLETED:
      convertedType = StatusTypeEnum.COMPLETED;
      break;
    case StatusTypeEnum.SUBMITTED:
      convertedType = StatusTypeEnum.SUBMITTED;
      break;
    case StatusTypeEnum.ACCEPTED:
      convertedType = StatusTypeEnum.ACCEPTED;
      break;
  }
  return <StyledStatus type={convertedType}>{`${convertedType}: ${count}`}</StyledStatus>;
};

export const UserStats = ({
  userAppStats,
  total,
}: {
  userAppStats?: UserStatsProps | UserAppStatsProps;
  total?: number;
}) => {
  return (
    <StatsBlock>
      <StatsItem>
        Total applications: <strong>{total}</strong>
      </StatsItem>
      <Statuses>
        {userAppStats &&
          Object.entries(userAppStats).map(([status, count]: [status: string, count: number]) => {
            return <Status key={status} type={status} count={count} />;
          })}
      </Statuses>
    </StatsBlock>
  );
};
