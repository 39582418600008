import { InputProps } from "./types";
import { InputField, StyledTextArea, InputContainer } from "./styles";

export const TextArea = ({
  icon,
  type,
  error,
  label,
  onSearchClear,
  variant = "default",
  withErrorMessage = true,
  ...props
}: InputProps) => {
  return (
    <InputContainer>
      <InputField withErrorMessage={withErrorMessage}>
        <StyledTextArea error={error} withIcon={!!icon} variant={variant} {...props} />
      </InputField>
    </InputContainer>
  );
};
