import { StatusTypeEnum, ResourceCategoryEnum } from "types";

const white = "#fff";
const black = "#0F202F";
const blue = "#4B9CE6";
const secondaryBlue = "#D8ECFF";
const bgWhite = "#F6FAFF";
const grey = "#7791AA";
const greyPlaceholder = "#9FA9B3";
const strokeGrey = "#ECEEEF";
const red = "#F9604B";

const status = Object.freeze({
  [StatusTypeEnum.PENDING]: {
    bg: "rgba(251, 153, 5, 0.16)",
    color: "#FEA117",
  },
  [StatusTypeEnum.DECLINED]: {
    bg: "rgba(249, 96, 75, 0.16)",
    color: red,
  },
  [StatusTypeEnum.COMPLETED]: {
    bg: "rgba(52, 168, 83, 0.16)",
    color: "#45AF61",
  },
  [StatusTypeEnum.SUBMITTED]: {
    bg: "rgba(51, 212, 255, 0.16)",
    color: "#33D4FF",
  },
  [StatusTypeEnum.ACCEPTED]: {
    bg: "rgba(52, 168, 83, 0.16)",
    color: "#45AF61",
  },
});

const button = Object.freeze({
  primary: {
    color: white,
    bg: {
      default: blue,
      hover: "#3484CD",
      active: "#1C67AC",
    },
  },
  secondary: {
    color: blue,
    bg: {
      default: "rgba(75, 156, 230, 0.08)",
      hover: "rgba(75, 156, 230, 0.16)",
      active: "rgba(75, 156, 230, 0.24)",
    },
  },
  negative: {
    color: white,
    bg: {
      default: red,
      hover: "#e05643",
      active: "#c74c3c",
    },
  },
  transparent: {
    color: blue,
    bg: {
      default: "transparent",
      hover: "transparent",
      active: "transparent",
    },
  },
});

const category = Object.freeze({
  [ResourceCategoryEnum.MENTAL_WELLNESS]: {
    color: "#F94BA9",
    bg: {
      default: "#F6E1EC",
    },
  },
  [ResourceCategoryEnum.ACADEMIC_DEVELOPMENT]: {
    color: "#34A853",
    bg: {
      default: "#E1F6E7",
    },
  },
  [ResourceCategoryEnum.CAREER_DEVELOPMENT]: {
    color: "#FE7817",
    bg: {
      default: "#F6EAE1",
    },
  },
  [ResourceCategoryEnum.HOUSING_SUPPORT]: {
    color: "#9F34A8",
    bg: {
      default: "#F4E1F6",
    },
  },
  [ResourceCategoryEnum.FOOD]: {
    color: "#FEA117",
    bg: {
      default: "#F6EEE1",
    },
  },
  [ResourceCategoryEnum.PHYSICAL_HEALTH]: {
    color: "#F9604B",
    bg: {
      default: "#F6E4E1",
    },
  },
  [ResourceCategoryEnum.PUBLIC_RESTROOMS]: {
    color: "#4BBAF9",
    bg: {
      default: "#E1ECF6",
    },
  },
  [ResourceCategoryEnum.PUBLIC_COMPUTERS]: {
    color: "#546778",
    bg: {
      default: "#E1EEF6",
    },
  },
  [ResourceCategoryEnum.LEGAL_HELP]: {
    color: "#BB6F4F",
    bg: {
      default: "#F6E7E1",
    },
  },
  [ResourceCategoryEnum.WATER_FOUNTAINS]: {
    color: "#4BBAF9",
    bg: {
      default: "#E1EEF6",
    },
  },
  default: "black",
  active: blue,
});

const border = Object.freeze({
  default: strokeGrey,
  hover: "#A5B4BD",
  active: blue,
  error: red,
});

const background = Object.freeze({
  main: white,
  layout: bgWhite,
});

const input = Object.freeze({
  color: {
    placeholder: greyPlaceholder,
    text: black,
    error: red,
  },
  bg: {
    default: white,
    disabled: "#f6f6f6",
    error: "#f7f7f7",
  },
  svg: {
    default: black,
    disabled: strokeGrey,
    error: red,
  },
});

export const COLORS = Object.freeze({
  blue,
  grey,
  white,
  bgWhite,
  input,
  status,
  border,
  button,
  category,
  strokeGrey,
  mainBg: white,
  secondaryBlue,
  bg: background,
  greyPlaceholder,
  layoutBg: bgWhite,
  textPrimary: black,
  textSecondary: grey,
});
