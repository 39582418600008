import { useMediaQuery } from "react-responsive";

import { Quote } from "components/quote";

import { AuthLayoutProps } from "./types";
import { LeftSide, RightSide, FormBlock, BgImageBlock, PageContainer } from "./styles";
import { device } from "utils/device";

export const AuthLayout = ({ children }: AuthLayoutProps) => {
  const isMobile = useMediaQuery({ query: device.tablet });

  return (
    <PageContainer>
      {isMobile ? (
        <LeftSide>
          <BgImageBlock>
            <RightSide>
              <FormBlock>{children}</FormBlock>
            </RightSide>
            <Quote />
          </BgImageBlock>
        </LeftSide>
      ) : (
        <>
          <LeftSide>
            <BgImageBlock>
              <Quote />
            </BgImageBlock>
          </LeftSide>
          <RightSide>
            <FormBlock>{children}</FormBlock>
          </RightSide>
        </>
      )}
    </PageContainer>
  );
};
