import { FieldHookConfig, useField } from "formik";

import { Input } from "components";
import { InputProps } from "components/input/types";

export const FormikInput = ({
  handleIconButtonClick,
  iconButton,
  icon,
  type,
  label,
  ...props
}: InputProps & FieldHookConfig<string>) => {
  const [field, meta] = useField(props);
  const isError = Boolean(meta.error) && meta.touched;

  return (
    <Input
      label={label}
      type={type}
      error={isError ? meta.error : undefined}
      icon={icon}
      iconButton={iconButton}
      handleIconButtonClick={handleIconButtonClick}
      {...field}
      {...props}
    />
  );
};
