import styled from "styled-components";
import { Form } from "formik";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  overflow: auto;
  gap: 1rem;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const Container = styled.div`
  align-self: center;
  height: 100%;
  width: 55%;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledForm = styled(Form)`
  width: 100%;
  align-self: center;
  align-items: center;
`;

export { Container, Row, StyledForm };
