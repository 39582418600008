import { AcceptDropdown, RejectDropdown } from "./styles";
import { AcceptDropdownProps } from "./types";
import { useState } from "react";
import { applicantStatusEnum } from "utils/enums";

export const StatusDropdown = ({ initialStatus, onReview }: AcceptDropdownProps) => {
  const [status, setStatus] = useState<"accepted" | "declined">(initialStatus);
  const selectProps = {
    defaultValue: status,
    value: status,
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => {
      setStatus(e.target.value as "accepted" | "declined");
      onReview(e.target.value as "accepted" | "declined");
    },
  };

  const options = (
    <>
      <option value={applicantStatusEnum.ACCEPTED}>Accepted</option>
      <option value={applicantStatusEnum.DECLINED}>Declined</option>
    </>
  );
  return status === applicantStatusEnum.ACCEPTED ? (
    <AcceptDropdown {...selectProps}> {options} </AcceptDropdown>
  ) : (
    <RejectDropdown {...selectProps}> {options} </RejectDropdown>
  );
};
