import { configureStore, combineReducers, ConfigureStoreOptions, Reducer, AnyAction } from "@reduxjs/toolkit";
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import storage from "redux-persist/lib/storage";

import { userReducer } from "store/user/slice";
import { authReducer } from "store/auth/slice";
import { applicationReducer } from "store/application/slice";
import { errorReducer } from "store/main/errorSlice";
import { orgReducer } from "store/organization/slice";
import { publicDeviceReducer } from "store/publicDevice/slice";
import { publicBathroomReducer } from "store/publicBathroom/slice";
import { mapReducer } from "./map/slice";

import { authApi } from "./auth/api";
import { userApi } from "./user/api";
import { applicationApi } from "./application/api";
import { publicDeviceApi } from "store/publicDevice/api";
import { publicBathroomApi } from "store/publicBathroom/api";
import { orgApi } from "./organization/api";
import { mapApi } from "./map/api";
import { interactionApi } from "./interaction/api";
import { interactionReducer } from "./interaction/slice";

const _rootReducer = combineReducers({
  /* State Reducers*/
  user: userReducer,
  auth: authReducer,
  application: applicationReducer,
  error: errorReducer,
  org: orgReducer,
  publicDevice: publicDeviceReducer,
  publicBathroom: publicBathroomReducer,
  map: mapReducer,
  interaction: interactionReducer,

  /* API Reducers
   ***Make sure to add middleware below!!!!***
   ***Make sure to explicitly blacklist it in persist config below!!!!***
   */
  [authApi.reducerPath]: authApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [applicationApi.reducerPath]: applicationApi.reducer,
  [orgApi.reducerPath]: orgApi.reducer,
  [publicDeviceApi.reducerPath]: publicDeviceApi.reducer,
  [publicBathroomApi.reducerPath]: publicBathroomApi.reducer,
  [mapApi.reducerPath]: mapApi.reducer,
  [interactionApi.reducerPath]: interactionApi.reducer,
});

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === "main/destroySession") {
    // this applies to all keys defined in persistConfig(s)
    localStorage.removeItem("persist:root");

    state = {} as RootState;
  }
  return _rootReducer(state, action);
};

/* Persist configs 
    auth slice state is persisted;
*/
const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["auth"],
  blacklist: [
    authApi.reducerPath,
    userApi.reducerPath,
    applicationApi.reducerPath,
    orgApi.reducerPath,
    publicBathroomApi.reducerPath,
    publicDeviceApi.reducerPath,
    mapApi.reducerPath,
    interactionApi.reducerPath,
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const createStore = (options?: ConfigureStoreOptions["preloadedState"] | undefined) =>
  configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV === "development",
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          /* Needed to connect redux persist and redux toolkit*/
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(
        authApi.middleware,
        userApi.middleware,
        applicationApi.middleware,
        orgApi.middleware,
        publicBathroomApi.middleware,
        publicDeviceApi.middleware,
        mapApi.middleware,
        interactionApi.middleware,
      ),
    ...options,
  });

export const store = createStore();
export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export type RootState = ReturnType<typeof _rootReducer>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
