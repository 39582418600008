import { ActionButton } from "components/action-button";
import { FormikSelect } from "components/formik-select";
import { Modal } from "components/modal";
import { Form, Formik } from "formik";
import React from "react";
import { ButtonsBlock, Container, DeclineContainer, DeclineHeading, DeclineText } from "./styles";
import { ApplicationReviewRequestBody } from "types";
import { useToast } from "hooks/use-toast";

interface DeclineApplicantModalProps {
  onClose: () => void;
  isOpen: boolean;
  onReview: (req: ApplicationReviewRequestBody) => void;
  name: string;
  email: string;
}

export const DeclineApplicantModal: React.FC<DeclineApplicantModalProps> = ({
  onClose,
  isOpen,
  onReview,
  name,
  email,
}) => {
  const { notifyEmail, notifyError } = useToast();

  const handleReview = ({ declineReason }: any) => {
    onReview({ message: declineReason, status: "declined", userName: name, userEmail: email, notifyUser: "true" });
    notifyEmail();
    onClose();
  };

  const declinedOptions = [
    { value: "invalidRequirements", label: "Doesn't match requirements" },
    { value: "fullCapacity", label: "At full capacity, please apply again later" },
    { value: "other", label: "Other" },
  ];

  return (
    <Modal maxWidth="400px" closeModal={onClose} onBackdropClick={onClose} isOpen={isOpen}>
      <Container>
        <DeclineContainer>
          <DeclineHeading>Decline</DeclineHeading>

          <DeclineText>
            Would you really like to decline this applicant? The applicant’s profile will be automatically moved to
            reviewed, and the applicant will be notified by ClearPath.
            <br />
          </DeclineText>

          <Formik initialValues={{ declineReason: "" }} onSubmit={handleReview}>
            <Form>
              <FormikSelect
                name="declineReason"
                label="Why are you declining this applicant?"
                placeholder="Choose reason"
                options={declinedOptions}
              />
              <ButtonsBlock>
                <ActionButton variant="secondary" size="fullWidth" onClick={onClose}>
                  Cancel
                </ActionButton>
                <ActionButton variant="negative" size="fullWidth" type="submit" onClick={notifyError}>
                  Yes, decline
                </ActionButton>
              </ButtonsBlock>
            </Form>
          </Formik>
        </DeclineContainer>
      </Container>
    </Modal>
  );
};
