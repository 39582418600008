import { useMediaQuery } from "react-responsive";

import { device } from "utils/device";
import { ClearpathLogo, IconButton } from "components";

import { HeaderContainer, UserIconButton } from "./styles";
import { useTypedSelector } from "store";

import { selectOrgState } from "store/organization/selectors";
import { StyledPopup } from "components/sort-by-popup/styles";
import { Dropdown } from "components/user-dropdown";

export const Header = () => {
  const isMobile = useMediaQuery({ query: device.tablet });

  const { currentOrg } = useTypedSelector(selectOrgState);

  const trigger = isMobile ? (
    <IconButton icon="burger-menu" iconButtonCssProps={{ zIndex: 1 }} />
  ) : (
    <UserIconButton icon="user" text={currentOrg?.name} color="#4B9CE6" />
  );

  return (
    <HeaderContainer>
      <ClearpathLogo size={isMobile ? "small" : "big"} />
      <StyledPopup trigger={trigger}>
        <Dropdown isMobile={isMobile} isOrganization />
      </StyledPopup>
    </HeaderContainer>
  );
};
