import { Field, FieldInputProps } from "formik";

import { Checkbox } from "components";
import { CheckboxProps } from "components/checkbox/types";

export const FormikCheckbox = ({ name, ...props }: CheckboxProps) => {
  return (
    <Field name={name} type="checkbox">
      {({ field }: { field: FieldInputProps<string> }) => <Checkbox {...field} {...props} />}
    </Field>
  );
};
