import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FilterName, MapFilters } from "pages/main/home/map/types";
import { filterOrgs, initializeFilterFromEnum, relevantLocation } from "pages/main/home/map/utils";
import {
  AgeGroupEnum,
  DistanceEnum,
  FilterCategoryEnum,
  LatLng,
  LocationTypeEnum,
  Organization,
  PublicBathroom,
  ResourceCategoryEnum,
  TargetCommunityEnum,
} from "types";

export type MapState = {
  originalOrgs: Organization[];
  originalBathrooms: PublicBathroom[];
  filteredOrgs: Organization[];
  count: number;
  bathroomCount: number;
  filters: MapFilters;
  searchQuery: string;
  currentLocation: LatLng | null;
  specifiedLocation: LatLng | null;
  selectedResource: Organization | null;
};

export const initialFilters: MapFilters = {
  [FilterCategoryEnum.RESOURCES_OFFERED]: initializeFilterFromEnum(ResourceCategoryEnum),
  [FilterCategoryEnum.TARGET_COMMUNITIES]: initializeFilterFromEnum(TargetCommunityEnum),
  [FilterCategoryEnum.AGE_GROUPS]: initializeFilterFromEnum(AgeGroupEnum),
  [FilterCategoryEnum.DISTANCE]: initializeFilterFromEnum(DistanceEnum),
  [FilterCategoryEnum.LOCATION_TYPE]: initializeFilterFromEnum(LocationTypeEnum),
};

const mapInitialState: MapState = {
  originalOrgs: [],
  originalBathrooms: [],
  filteredOrgs: [],
  count: 0,
  bathroomCount: 0,
  filters: initialFilters,
  searchQuery: "",
  currentLocation: null,
  specifiedLocation: null,
  selectedResource: null,
};

const mapSlice = createSlice({
  name: "map",
  initialState: mapInitialState,
  reducers: {
    updateOriginalOrgs(state, action: PayloadAction<Pick<MapState, "originalOrgs" | "count">>) {
      const { originalOrgs, count } = action.payload;
      state.originalOrgs = originalOrgs;
      state.count = count;
      state.filteredOrgs = filterOrgs(
        state.originalOrgs,
        state.originalBathrooms,
        state.filters,
        state.searchQuery,
        relevantLocation(state),
      );
    },
    updateOriginalBathrooms(state, action: PayloadAction<Pick<MapState, "originalBathrooms" | "count">>) {
      const { originalBathrooms, count } = action.payload;
      state.originalBathrooms = originalBathrooms;
      state.bathroomCount = count;
      state.filteredOrgs = filterOrgs(
        state.originalOrgs,
        state.originalBathrooms,
        state.filters,
        state.searchQuery,
        relevantLocation(state),
      );
    },
    toggleFilter(state, action: PayloadAction<{ filterCategory: FilterCategoryEnum; filterName: FilterName }>) {
      const { filterCategory, filterName } = action.payload;

      const filterGroup = state.filters[filterCategory] as Record<string, boolean>;

      if (filterName in filterGroup) {
        filterGroup[filterName] = !filterGroup[filterName];

        state.filteredOrgs = filterOrgs(
          state.originalOrgs,
          state.originalBathrooms,
          state.filters,
          state.searchQuery,
          relevantLocation(state),
        );
      }
    },
    applyFilters(state, action: PayloadAction<MapFilters>) {
      state.filters = action.payload;
      state.filteredOrgs = filterOrgs(
        state.originalOrgs,
        state.originalBathrooms,
        state.filters,
        state.searchQuery,
        relevantLocation(state),
      );
    },
    applySearch(state, action: PayloadAction<string>) {
      state.searchQuery = action.payload;

      state.filteredOrgs = filterOrgs(
        state.originalOrgs,
        state.originalBathrooms,
        state.filters,
        state.searchQuery,
        relevantLocation(state),
      );
    },
    updateSpecifiedLocation(state, action: PayloadAction<LatLng>) {
      state.specifiedLocation = action.payload;

      state.filteredOrgs = filterOrgs(
        state.originalOrgs,
        state.originalBathrooms,
        state.filters,
        state.searchQuery,
        relevantLocation(state),
      );
    },
    updateCurrentLocation(state, action: PayloadAction<LatLng>) {
      state.currentLocation = action.payload;

      state.filteredOrgs = filterOrgs(
        state.originalOrgs,
        state.originalBathrooms,
        state.filters,
        state.searchQuery,
        relevantLocation(state),
      );
    },
    updateSelectedResource(state, action: PayloadAction<Organization | null>) {
      state.selectedResource = action.payload;
    },
    clearFilters(state) {
      state.filters = initialFilters;

      state.filteredOrgs = filterOrgs(
        state.originalOrgs,
        state.originalBathrooms,
        state.filters,
        state.searchQuery,
        relevantLocation(state),
      );
    },
  },
});

const {
  reducer: mapReducer,
  actions: {
    updateOriginalOrgs,
    updateOriginalBathrooms,
    toggleFilter,
    applySearch,
    updateSpecifiedLocation,
    updateCurrentLocation,
    updateSelectedResource,
    clearFilters,
    applyFilters,
  },
} = mapSlice;

export {
  updateOriginalOrgs,
  updateOriginalBathrooms,
  mapReducer,
  toggleFilter,
  applySearch,
  updateSpecifiedLocation,
  updateCurrentLocation,
  updateSelectedResource,
  clearFilters,
  applyFilters,
};
