import { Header } from "./header";
import { OrganizationLayoutProps } from "./types";
import { ContentContainer, MainContainer, PageContainer } from "./styles";

export const OrganizationLayout = ({ children }: OrganizationLayoutProps) => {
  return (
    <MainContainer>
      <PageContainer>
        <Header />
        <ContentContainer>{children}</ContentContainer>
      </PageContainer>
    </MainContainer>
  );
};
