import { css, keyframes } from "styled-components";

export const linkStyles = css`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: ${(p) => p.theme.colors.blue};
`;

export const loaderRotateAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  } 
  100% {
    transform: rotate(360deg);
  }
`;
