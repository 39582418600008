import { Formik } from "formik";
import { OrganizationClaimProps, FormValues } from "./types";
import { ClaimOrganization } from "components/claim-organization";
import { Input, Loader } from "components";
import { SearchBar, StyledForm, Button } from "./styles";
import { useEffect, useMemo, useState } from "react";
import { BoroughEnum, ResourceCategoryEnum, ValueOfEnum } from "types";
import { useGetUnclaimedOrgs } from "store/organization/api";

export const OrganizationClaimForm = ({
  setFormId,
  nextClick,
  setContactForm,
  setDetailsForm,
}: OrganizationClaimProps) => {
  const orgs = useGetUnclaimedOrgs().data?.orgs;

  const [search, setSearch] = useState("");

  const filteredOrgs = useMemo(() => {
    let temp = orgs?.filter(({ name }) => name.toLowerCase().startsWith(search.toLowerCase()));
    return temp;
  }, [orgs, search]);

  // we will get this from the backend later
  useEffect(() => {
    setFormId("claim");
  }, [setFormId]);

  const handleSubmit = (values: FormValues) => {
    nextClick();
    setContactForm({
      orgName: values.orgName,
      orgEmail: values.orgEmail,
      orgType: "" as ResourceCategoryEnum,
      password: "",
      confirmPassword: "",
      phoneNumber: values.phoneNumber,
      id: values.id,
    });
    setDetailsForm({
      inPerson: "",
      borough: values.borough,
      address: values.address,
      lat: values.lat,
      long: values.long,
      targetCommunities: [],
      additionalResources: values.additionalResources,
      description: values.description,
      link: values.link,
      ageRange: { min: 0, max: 100 },
      requirements: [],
    });
  };

  const handleSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.key === "Enter" && e.preventDefault();
    e.key === "Enter" && setSearch(e.currentTarget.value);
  };

  const [selectedOrg, setSelectedOrg] = useState("");

  return (
    <Formik
      initialValues={{
        borough: "" as ValueOfEnum<BoroughEnum>,
        address: "",
        lat: 0,
        long: 0,
        additionalResources: [] as ValueOfEnum<ResourceCategoryEnum>[],
        description: "",
        link: "",
        orgName: "",
        orgEmail: "",
        orgType: "",
        phoneNumber: "",
        id: "",
      }}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, setValues, submitForm, handleSubmit }) => {
        return (
          <StyledForm
            id="claim"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <SearchBar>
              <Input icon="search" placeholder="Search" onKeyPress={handleSearch} withErrorMessage={false} />
              <Button onClick={submitForm}>Add Manually</Button>
            </SearchBar>
            <br />
            {!orgs && <Loader position="relative" />}
            <div>
              {orgs &&
                (filteredOrgs ? filteredOrgs : orgs).map((org) => (
                  <div
                    key={org.id}
                    onClick={() => {
                      setValues({
                        borough: org.borough || ("" as ValueOfEnum<BoroughEnum>),
                        orgName: org.name,
                        orgEmail: org.email,
                        link: org.url || "",
                        additionalResources: org.additionalResources,
                        description: org.description || "",
                        address: org.address || "",
                        lat: org.lat || 0,
                        long: org.long || 0,
                        orgType: org.orgType,
                        phoneNumber: org.contactNumber.replace(/\D/g, ""),
                        id: org.id,
                      });
                      setSelectedOrg(org.id);
                    }}
                  >
                    <ClaimOrganization org={org} isSelected={org.id === selectedOrg} />
                  </div>
                ))}
            </div>
          </StyledForm>
        );
      }}
    </Formik>
  );
};
