import styled, { css } from "styled-components";
import { device } from "utils/device";

import { StyledNotificationButtonProps } from "./types";

export const Container = styled.div`
  position: relative;
`;

export const NotificationButton = styled.button<StyledNotificationButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  position: relative;
  border-radius: 8px;

  width: 56px;
  height: 56px;

  @media ${device.tablet} {
    width: 24px;
    height: 24px;
  }

  ::after {
    content: "";
    display: block;
    position: absolute;
    top: 17px;
    right: 19px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    z-index: 1;
    border: 1px solid;
    border-color: transparent;

    @media ${device.tablet} {
      top: 2px;
      right: 2px;
    }

    ${(p) => css`
      background-color: ${p.hasNotifications ? p.theme.colors.blue : "transparent"};
    `};

    ${(p) => css`
      border-color: ${p.hasNotifications && p.theme.colors.white};
    `}
  }

  ${(p) =>
    css`
      background-color: ${p.isActive ? p.theme.colors.button.primary.bg.default : "transparent"};

      .notification-icon {
        path {
          fill: ${p.isActive ? p.theme.colors.white : p.theme.colors.grey};
        }
      }
    `}
`;
