import styled from "styled-components";

const SelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 95%;
  position: relative;
`;

const SearchBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (max-width: 768px) {
    width: 95%;
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  height: 100%;

  @media (max-width: 768px) {
    width: 85%;
  }
`;

const Button = styled.button`
  width: 12%;
  color: ${(p) => p.theme.colors.blue};

  margin: 0 1rem;
`;

export { SelectContainer, SearchBar, StyledForm, Button };
