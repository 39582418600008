import styled, { css } from "styled-components";

import { Icon } from "components";
import { loaderRotateAnimation } from "utils/styles";

export const loaderClassName = "page-loader";

interface LoaderWrapperProps {
  position?: "absolute" | "relative";
}

export const LoaderWrapper = styled.div<LoaderWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: ${(props) => props.position || "absolute"};
  top: 50%;
  left: ${(props) => (props.position === "relative" ? "auto" : "50%")};
  transform: ${(props) => (props.position === "relative" ? "translate(0%, -100%)" : "translate(-50%, -100%)")};
  height: 90px;
  width: 90px;
  border-radius: 12px;
  box-shadow: 0px 12px 56px 0px rgba(109, 162, 223, 0.16);

  ${(p) => css`
    background-color: ${p.theme.colors.bg.main};
    border: 1px solid ${p.theme.colors.border.default};

    .${loaderClassName} {
      width: 40px;
      height: 40px;
      animation: ${loaderRotateAnimation} 1s linear infinite;
      path {
        fill: ${p.theme.colors.blue};
      }
    }
  `}
`;

export const Loader = ({ position }: LoaderWrapperProps) => {
  return (
    <LoaderWrapper position={position}>
      <Icon icon="loader" className={loaderClassName} />
    </LoaderWrapper>
  );
};
