import styled, { css } from "styled-components";

export const TagsBlock = styled.div`
  display: flex;
  column-gap: 8px;
`;

export const StyledTag = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 6px 8px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;

  ${(p) => css`
    color: ${p.theme.colors.blue};
    background-color: ${p.theme.colors.secondaryBlue};
  `}
`;
