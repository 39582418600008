import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import { store, persistor } from "store";
import { Routes } from "routes";
import { DEFAULT_THEME } from "config/styles/theme";

import { PersistGate } from "redux-persist/integration/react";

import { GlobalStyles } from "./styles";
import "./fonts.css";
import { useWindowSize } from "react-use";
import { useState } from "react";

export function App() {
  const windowSize = useWindowSize();
  const [overflow, setOverflow] = useState("hidden");

  return (
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={DEFAULT_THEME}>
            <GlobalStyles windowSize={{ height: windowSize.height }} overflow={overflow} />
            <Routes setOverflow={setOverflow} />
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  );
}
