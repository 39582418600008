import { FormNavProps } from "./types";
import { Button, ButtonContainer } from "./styles";

export const FormNavigationButtons = ({ backClick, formId, nextClick, finalStepClick, final, start }: FormNavProps) => {
  // if there is no form id, then the button goes to the next step
  // if there is a form id, then the button submits the form (handled by formik)
  // if the button is the final step, then it submits all forms to the backend
  const handleNextClick = () => {
    if (formId === "no id") {
      nextClick();
    } else {
      if (final) {
        console.log("true");
        finalStepClick();
      }
    }
  };

  const handleBackClick = () => {
    backClick();
  };

  return (
    <ButtonContainer>
      {!start && (
        <Button
          form={formId}
          onClick={handleBackClick}
          marginRight="5%"
          variant="secondary"
          size="fullWidth"
          type="submit"
        >
          Back
        </Button>
      )}
      <Button
        form={formId}
        marginLeft="auto"
        onClick={handleNextClick}
        variant="primary"
        size="fullWidth"
        type="submit"
      >
        {final ? "Confirm" : "Continue"}
      </Button>
    </ButtonContainer>
  );
};
