import styled, { css } from "styled-components";

import { CheckboxContainerProps } from "./types";

export const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;

export const LabelContent = styled.span`
  display: inline-block;
`;

export const CheckboxContainer = styled.div<CheckboxContainerProps>`
  line-height: 0;
  margin-right: 8px;

  ${(p) =>
    p.disabled
      ? `opacity: 0.4;`
      : css`
          :hover .checkbox-default {
            transition: ${p.theme.transition};
            path {
              stroke: ${p.theme.colors.blue};
            }
          }
        `}
`;

export const StyledCheckbox = styled.input`
  display: none;
  visibility: hidden;
  width: 0;
  height: 0;
`;
