import React from "react";
import styled from "styled-components";
import { DesktopSelect, Input } from "components";
import { Popup } from "components/popup";
import { PopupProps } from "reactjs-popup/dist/types";
import { DateRange } from "types";

interface FiltersPopupProps {
  trigger: PopupProps["trigger"];
  status: string;
  setStatus: React.Dispatch<React.SetStateAction<string>>;
  dateRange: DateRange;
  setDateRange: React.Dispatch<React.SetStateAction<DateRange>>;
}

const ClearButton = styled.div`
  cursor: pointer;
  color: ${(p) => p.theme.colors.blue};
  font-weight: 500;
`;

const DateInput = styled(Input)`
  padding-right: 15px;

  &:before {
    content: attr(placeholder);
    color: #aaa;
    margin-right: 0.5em;
  }
`;

const StyledPopup = styled(Popup)`
  &-content {
    width: 300px;
    padding: 25px 20px;
    background-color: white;
    border-radius: 8px;
  }
`;

const options = [
  { label: "Accepted", value: "accepted" },
  { label: "Declined", value: "declined" },
];

export const FiltersPopup: React.FC<FiltersPopupProps> = ({ trigger, status, setStatus, dateRange, setDateRange }) => {
  const clearAll = () => {
    setStatus("");
    setDateRange({ from: "", to: "" });
  };

  const selectedOption = options.find((option) => option.value === status);

  return (
    <StyledPopup trigger={trigger} position="bottom right">
      <div onClick={(e) => e.stopPropagation()}>
        <DesktopSelect
          key={status}
          name="status"
          label="Status"
          options={options}
          placeholder={"Choose status"}
          value={selectedOption}
          onChange={(option) => setStatus(option?.value ?? "")}
        />
        <DateInput
          name="fromDate"
          label="Date"
          placeholder={"From"}
          type="date"
          value={dateRange.from}
          onChange={(e) => setDateRange({ ...dateRange, from: e.target.value })}
        />
        <DateInput
          name="toDate"
          placeholder={"To"}
          type="date"
          value={dateRange.to}
          onChange={(e) => setDateRange({ ...dateRange, to: e.target.value })}
        />
        <ClearButton onClick={clearAll}>Clear All</ClearButton>
      </div>
    </StyledPopup>
  );
};
