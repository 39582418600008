import styled, { css } from "styled-components";
import { device } from "utils/device";

import { StyledModalContentWrapProps } from "./types";

const fullScreenStyles = css`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 100;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${fullScreenStyles}
`;

export const ModalContentWrap = styled.div<StyledModalContentWrapProps>`
  flex-grow: 1;
  position: relative;
  max-width: ${(p) => p.maxWidth && p.maxWidth};
  z-index: 100;

  @media ${device.tablet} {
    width: 100%;
    height: 100%;
  }
`;

export const Backdrop = styled.div<StyledModalContentWrapProps>`
  ${fullScreenStyles}
  background-color: ${(p) => (p.isPopup ? "rgba(0, 0, 0, 0)" : "rgba(0, 0, 0, 0.3)")};
`;

export const CloseIconButton = styled.button`
  position: absolute;
  top: 24px;
  right: 24px;
  z-index: 1;
`;
