import styled from "styled-components";
import { FormikSelect } from "components";

const EinSelect = styled(FormikSelect)`
  font-size: 1rem;
  width: 100%;
  @media (max-width: 768px) {
    width: 16.5rem;
    height: 10rem;
  }
`;

const FormContainer = styled.div`
  width: 55%;
  height: 40vh;
  align-self: center;
`;

export { EinSelect, FormContainer };
