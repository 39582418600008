import { useRef, useEffect, useState } from "react";

export const useDetectClickOut = (initState: boolean) => {
  const nodeRef = useRef<HTMLInputElement>(null);
  const triggerRef = useRef<HTMLInputElement>(null);

  const [show, setShow] = useState(initState);

  const handleClickOutside = (event: any) => {
    if (triggerRef && triggerRef.current && triggerRef.current.contains(event.target)) {
      return setShow(!show);
    }

    if (nodeRef && nodeRef.current && !nodeRef.current.contains(event.target)) {
      return setShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return { show, nodeRef, setShow, triggerRef };
};
