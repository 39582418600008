import { createSlice } from "@reduxjs/toolkit";

const mainSlice = createSlice({
  name: "main",
  initialState: {
    id: Math.random(),
    v1: {},
  },
  reducers: {
    // ...
    destroySession() {
      // Note that this should be left intentionally empty.
      // Clearing redux state and localForage happens in rootReducer.ts.
    },
  },
});

export const { destroySession } = mainSlice.actions;
