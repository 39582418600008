import { Suspense, useEffect } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";

import { Loader } from "components";

import { PrivateRoute, PublicRoute } from "./helpers";
import { ORGANIZATION_ROUTES, PRIVATE_ROUTES, PUBLIC_ROUTES } from "./config";
import { useLogout, useRefreshSession } from "store/auth/api";
import { useTypedSelector } from "store";
import { selectAuthSessionStatus } from "store/auth/selectors";
import { selectOrgState } from "store/organization/selectors";
import { selectUserDetails } from "store/user/selectors";

const LogoutComp = () => {
  const history = useHistory();
  const [logout] = useLogout();

  useEffect(() => {
    logout();
    history.push("/signin");
  }, [history, logout]);
  return <Loader />;
};

interface RoutesProps {
  setOverflow: (overflow: string) => void;
}

export function Routes({ setOverflow }: RoutesProps) {
  const isAuthed = useTypedSelector(selectAuthSessionStatus);
  const { currentOrg } = useTypedSelector(selectOrgState);
  const { id: userId } = useTypedSelector((state) => selectUserDetails(state));

  const { isError } = useRefreshSession(undefined, { skip: !isAuthed });
  const location = useLocation();

  useEffect(() => {
    const route = location.pathname.split("/")[1];
    const overflow = route === "dashboard" ? "hidden" : "auto";

    setOverflow(overflow);
  }, [location, setOverflow]);

  if (isError) {
    return <LogoutComp />;
  }

  // if user is authed, ensure refresh is completed before return routes
  if (isAuthed && !currentOrg && !userId) {
    return <Loader />;
  }

  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route exact path="/logout" component={LogoutComp} />
        {PRIVATE_ROUTES.map(({ path, exact, component }) => (
          <PrivateRoute
            path={path}
            exact={exact}
            component={component}
            key={Array.isArray(path) ? path.join("") : path}
          />
        ))}
        {ORGANIZATION_ROUTES.map(({ path, exact, component }) => (
          <PrivateRoute
            path={path}
            exact={exact}
            component={component}
            key={Array.isArray(path) ? path.join("") : path}
            isOrganizationRoute
          />
        ))}
        {PUBLIC_ROUTES.map(({ path, exact, component }) => (
          <PublicRoute
            path={path}
            exact={exact}
            component={component}
            key={Array.isArray(path) ? path.join("") : path}
          />
        ))}
      </Switch>
    </Suspense>
  );
}
