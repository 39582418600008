import { IconButton } from "components/icon-button";
import styled, { css } from "styled-components";
import { device } from "utils/device";

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 80px;
  max-height: 80px;
  padding: 0 40px;
  background-color: ${(p) => p.theme.colors.bg.main};
  @media ${device.tablet} {
    padding: 0 16px;
    margin-bottom: 0;
    min-height: 56px;
    max-height: 56px;
  }
`;

export const OptionsBlock = styled.div`
  display: flex;
  grid-column-gap: 8px;
`;

export const UserIconButton = styled(IconButton)`
  align-items: center;
  padding: 10px;
  grid-column-gap: 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  border-radius: 8px;
  ${(p) => css`
    border: 1px solid ${p.theme.colors.border.default};
    background-color: ${p.theme.colors.bg.layout};
    border-color: ${p.theme.colors.border.active};
  `}
`;

export const MobileIcons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;

  .bell-mobile {
    margin-right: 20px;
  }
`;
