import styled from "styled-components";

export const Dropdown = styled.select`
  border: 0.1rem none;
  border-radius: 0.25rem;
  color: #000000;
  width: 95px;
  height: 1.3rem;
  font-size: 1rem;
  padding-left: 0.5rem;
  // @media (max-width: 768px) {
  //   width: 50%;
  // }
`;

export const AcceptDropdown = styled(Dropdown)`
  background-color: rgba(52, 168, 83, 0.2);
`;

export const RejectDropdown = styled(Dropdown)`
  background-color: rgba(249, 96, 75, 0.2);
`;
