import { Icon } from "components/icon";
import { COLORS } from "config/styles/colors";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { device } from "utils/device";

export const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px 40px;
  position: relative;
  height: 100%;
  flex-grow: 1;
  align-items: center;

  @media ${device.tablet} {
    padding: 0 16px 16px;
  }
`;

export const Heading = styled.h3`
  display: flex;
  font-size: 32px;
  font-weight: 800;
  line-height: 40px;
  text-align: center;
  justify-content: center;
  padding-bottom: 15px;
  color: ${(p) => p.theme.colors.textPrimary};
`;

export const StatusIcon = styled(Icon)`
  margin-bottom: 15px;
  width: 64px;
  height: 64px;
  fill: ${(p) => p.theme.colors.blue};
`;

export const ReturnText = styled(Link)`
  display: flex;
  font-size: 24px;
  font-weight: 100;
  line-height: 24px;
  justify-content: center;
  padding-bottom: 15px;
  color: ${COLORS.textPrimary};
`;

export const ContactText = styled.p`
  display: flex;
  font-size: 16px;
  font-weight: 50;
  line-height: 24px;
  color: ${COLORS.grey};
`;

export const EmailText = styled.a`
  display: flex;
  font-size: 16px;
  font-weight: 50;
  line-height: 24px;
  color: ${COLORS.blue};
`;

export const ContactContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  column-gap: 5px;
`;
