import { COLORS } from "./colors";

declare module "styled-components" {
  export interface DefaultTheme {
    colors: typeof COLORS;
    transition: string;
  }
}

export const DEFAULT_THEME = Object.freeze({
  colors: COLORS,
  transition: "all 0.2s ease-in-out",
});
