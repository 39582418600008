import { useMediaQuery } from "react-responsive";
import { device } from "utils/device";

import { SelectProps } from "./types";
import { MobileSelect } from "./mobile-select";
import { DesktopSelect } from "./desktop-select";

const Select = ({ ...props }: SelectProps) => {
  const isMobile = useMediaQuery({ query: device.tablet });
  return isMobile ? <MobileSelect {...props} /> : <DesktopSelect {...props} />;
};

export { Select, MobileSelect, DesktopSelect };
