import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PublicDevice } from "types";

type PublicDeviceInitialState = {
  collection: PublicDevice[] | null;
  count: number;
};

const publicDeviceInitialState: PublicDeviceInitialState = {
  collection: null,
  count: 0,
};

const publicDeviceSlice = createSlice({
  name: "publicDevice",
  initialState: publicDeviceInitialState,
  reducers: {
    updatePublicDevices(state, action: PayloadAction<typeof publicDeviceInitialState>) {
      state = { ...state, ...action.payload };
      return state;
    },
  },
});

const {
  reducer: publicDeviceReducer,
  actions: { updatePublicDevices },
} = publicDeviceSlice;

export type UserState = typeof publicDeviceInitialState;
export { updatePublicDevices, publicDeviceReducer };
