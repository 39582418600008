import styled from "styled-components";

export const AutoCompleteContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
`;

export const CustomGooglePlacesAutocomplete = styled.div`
  /* Hide the default dropdown indicator (arrow) */
  > div > div > div {
    display: none !important;
  }

  /* Add a search icon using pseudo-elements */
  > div > div::before {
    content: "";
    display: inline-block;
    width: 16px; /* Set the desired width for the search icon */
    height: 16px; /* Set the desired height for the search icon */
    background-image: url("path/to/search-icon.png"); /* Replace with the path to your search icon image */
    background-size: cover;
    margin-right: 8px; /* Add margin as needed */
  }
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const SearchIcon = styled.div`
  position: absolute;
  bottom: 0;
  left: 10px;
  width: 25px;
  height: 100%;
  display: flex;
  align-items: center;

  path {
    stroke: ${(p) => p.theme.colors.grey};
  }
`;

export const ErrorText = styled.p`
  position: relative;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: ${(p) => p.theme.colors.input.color.error};
`;
