import * as Yup from "yup";

export const taxValidationSchema = Yup.object().shape({
  type: Yup.string()
    .default(() => "")
    .required("Verification method is a required field"),
  ein: Yup.string().when("type", {
    is: "ein",
    then: Yup.string()
      .default(() => "")
      .required("EIN is a required field"),
  }),
  taxNumber: Yup.string().when("type", {
    is: "tax",
    then: Yup.string()
      .default(() => "")
      .required("Tax Number is a required field"),
  }),
});

export const taxInitialValues = taxValidationSchema.cast({});
