import { Formik } from "formik";
import { FormikInput, FormikSelect } from "components";
import { Container, Row, StyledForm } from "./styles";
import { OrganizationContactProps } from "./types";
import { ContactInfo, resourceCategoryOptions } from "types";
import { useEffect, useState } from "react";
import { contactValidationSchema } from "./config";
import { useGetEmailValidation } from "store/organization/api";

export const OrganizationContactForm = ({ setFormId, contact, setForm, nextClick }: OrganizationContactProps) => {
  useEffect(() => {
    setFormId("contact");
  }, [setFormId]);
  const [emailValidation] = useGetEmailValidation();
  const [emailError, setEmailError] = useState("");

  const handleFormSubmit = async (values: ContactInfo) => {
    const req = {
      email: values.orgEmail,
      claimedOrganizationId: contact.id,
    };

    const res = await emailValidation(req);
    if ("error" in res) {
      setEmailError("Email has already been claimed.");
    } else {
      setForm(values);
      nextClick();
    }
  };

  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={contact}
        validationSchema={contactValidationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={handleFormSubmit}
      >
        {({ handleSubmit, errors }) => {
          return (
            <StyledForm
              id="contact"
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <FormikInput
                name="orgName"
                label="Organization Name"
                type="text"
                placeholder="Organization Name"
                autoComplete="off"
              />
              <FormikSelect
                name="orgType"
                label="What resource category best describes your organization?"
                placeholder="Choose category"
                options={resourceCategoryOptions}
              />
              <Row>
                <FormikInput
                  name="orgEmail"
                  label="Email"
                  type="email"
                  placeholder="Email"
                  autoComplete="off"
                  error={errors.orgEmail ? errors.orgEmail : emailError}
                />
                <FormikInput
                  name="phoneNumber"
                  label="Phone Number"
                  type="text"
                  placeholder="Phone Number"
                  autoComplete="off"
                />
              </Row>
              <Row>
                <FormikInput
                  name="password"
                  label="Password"
                  type="password"
                  placeholder="Password"
                  autoComplete="off"
                />
                <FormikInput
                  name="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  placeholder="Confirm Password"
                  autoComplete="off"
                />
              </Row>
            </StyledForm>
          );
        }}
      </Formik>
    </Container>
  );
};
