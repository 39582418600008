import { useMediaQuery } from "react-responsive";

import { device } from "utils/device";
import { ClearpathLogo, IconButton } from "components";

import { HeaderContainer, UserIconButton } from "./styles";
import { selectUserDetails } from "store/user/selectors";
import { useTypedSelector } from "store";
import { useEffect, useState } from "react";

import { selectAuthSessionStatus } from "store/auth/selectors";
import { useRefreshSession } from "store/auth/api";
import { Dropdown } from "components/user-dropdown";
import { StyledPopup } from "components/sort-by-popup/styles";

export const Header = () => {
  const [skipRefresh, setSkipRefresh] = useState(true);
  const isMobile = useMediaQuery({ query: device.tablet });

  const isAuth = useTypedSelector((state) => selectAuthSessionStatus(state));
  const userDetails = useTypedSelector((state) => selectUserDetails(state));
  const { firstName, email } = userDetails;

  useRefreshSession(undefined, { skip: skipRefresh });

  useEffect(() => {
    if (isAuth) {
      //Means we must have user available
      if (email) return;
      else setSkipRefresh(false);
    }
  }, [isAuth, email]);

  const trigger = isMobile ? (
    <IconButton icon="burger-menu" iconButtonCssProps={{ zIndex: 1 }} />
  ) : (
    <UserIconButton icon="user" text={firstName} color="#4B9CE6" />
  );

  return (
    <HeaderContainer>
      <ClearpathLogo size={isMobile ? "small" : "big"} />
      <StyledPopup trigger={trigger}>
        <Dropdown isMobile={isMobile} />
      </StyledPopup>
    </HeaderContainer>
  );
};
