import styled from "styled-components";

export const Container = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 100;
  background-color: rgba(15, 32, 47, 0.4);

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const ContentContainer = styled.div`
  width: 100%;
  overflow: hidden;
  padding-bottom: 16px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: ${(p) => p.theme.colors.white};

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const Content = styled.div`
  width: 100%;
  /* max-height: 500px; */
`;

export const ActionButtonsContainer = styled.div`
  display: flex;
  row-gap: 16px;
  padding: 0 16px;
  margin-top: 16px;
  flex-direction: column;
`;
