import styled from "styled-components";

export const IconWrapper = styled.div`
  .close-icon {
    path {
      stroke: #f9604b;
    }
  }
  .check-icon {
    path {
      stroke: #34a853;
    }
  }
  .status-icon {
    path {
      stroke: #4b9ce6;
    }
  }
`;
