import { useState } from "react";

import { ReactComponent as EyeSvg } from "assets/icons/eye-on.svg";
import { Icon } from "../icon";

import { InputProps } from "./types";
import {
  ErrorText,
  IconBlock,
  InputField,
  InputLabel,
  StyledInput,
  InputContainer,
  ClearSearchBtn,
  IconButtonBlock,
} from "./styles";
import { IconButton } from "components/icon-button";

export const Input = ({
  handleIconButtonClick,
  iconButton,
  icon,
  type,
  error,
  label,
  inputContainerStyles,
  onSearchClear,
  variant = "default",
  withErrorMessage = true,
  ...props
}: InputProps) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <InputContainer style={inputContainerStyles}>
      {label && <InputLabel>{label}</InputLabel>}
      <InputField showPassword={showPassword} withErrorMessage={withErrorMessage}>
        {icon && (
          <IconBlock>
            <Icon icon={icon} />
          </IconBlock>
        )}
        {iconButton && (
          <IconButtonBlock>
            <IconButton type="button" icon={iconButton} onClick={handleIconButtonClick} />
          </IconButtonBlock>
        )}
        <StyledInput error={error} withIcon={!!icon} variant={variant} type={showPassword ? "text" : type} {...props} />
        {variant === "search" && <ClearSearchBtn onClick={onSearchClear}>Clear</ClearSearchBtn>}
        {type === "password" && !error && (
          <EyeSvg className="show-password-icon" onClick={() => setShowPassword(!showPassword)} />
        )}
        {error && <ErrorText>{error}</ErrorText>}
      </InputField>
    </InputContainer>
  );
};
