const API_V1 = "/api/v1";

export const BASE_URL = process.env.REACT_APP_BASE_URL + API_V1;

export const ROUTES = Object.freeze({
  SignUp: "/signup",
  SignIn: "/signin",
  Home: "/dashboard",
  Faqs: "/faqs",
  ResetPassword: "/reset-password",
  UserProfile: "/profile",
  HousingSupport: "/housing-support",
  ClearpathMap: "/clearpath-map",
  MentalWellness: "/mental-wellness",
  AcademicDevelopment: "/academic-development",
  CareerDevelopment: "/career-development",
  OrganizationHome: "/organization/dashboard",
  OrganizationProfile: "/organization/profile",
  OrganizationSignup: "/organization/signup",
  OrganizationStatus: "/organization/status",
  Logout: "/logout",
  NotFound: "*",
  Applications: "/applications",
});
