import { Link } from "react-router-dom";

import { ROUTES } from "config";
import logoBig from "assets/images/logo.png";
import logoSmall from "assets/images/logoSmallLogo.png";

import { LogoContainer } from "./styles";
import { ClearPathLogoProps } from "./types";

export const ClearpathLogo = ({ size }: ClearPathLogoProps) => {
  return (
    <LogoContainer>
      <Link to={ROUTES.Home}>
        {size === "big" ? (
          <img src={logoBig} alt="Clearpath logo" />
        ) : (
          <img src={logoSmall} alt="Clearpath logo small" />
        )}
      </Link>
    </LogoContainer>
  );
};
