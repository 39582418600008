import * as Yup from "yup";

export const contactValidationSchema = Yup.object().shape({
  orgName: Yup.string()
    .default(() => "")
    .required("Organization Name is a required field"),
  orgEmail: Yup.string()
    .email()
    .default(() => "")
    .required("Email is a required field"),
  orgType: Yup.string()
    .default(() => "")
    .required("Primary Category is a required field"),
  password: Yup.string()
    .default(() => "")
    .required("Password is a required field"),
  confirmPassword: Yup.string()
    .default(() => "")
    .required("Confirm Password is a required field")
    .oneOf([Yup.ref("password"), ""], "Passwords must match"),
  phoneNumber: Yup.string()
    .default(() => "")
    .required("Phone Number is a required field")
    .matches(/^\d{10}$/, "Must be 10 digits"),
});

export const contactInitialValues = contactValidationSchema.cast({});
