import { useMemo } from "react";
import { StylesConfig } from "react-select";
import { useTheme } from "styled-components";

import { IsMulti, OptionType } from "../types";

export const useStyles = () => {
  const theme = useTheme();

  const customStyles: StylesConfig<OptionType, IsMulti> = useMemo(
    () => ({
      placeholder: (css) => ({
        ...css,
        color: theme.colors.greyPlaceholder,
        margin: 0,
      }),
      control: (css, state) => ({
        ...css,
        transition: theme.transition,
        padding: "0 16px",
        boxShadow: "none",
        borderRadius: 8,
        border: `1px solid ${state.menuIsOpen ? theme.colors.border.active : theme.colors.border.default}`,
        backgroundColor: theme.colors.bg.main,
        height: "100%",
        minHeight: 56,
        "&:hover": {
          borderColor: state.isFocused ? theme.colors.border.active : theme.colors.border.hover,
        },
        ".dropdown-indicator-icon": {
          path: {
            fill: theme.colors.blue,
          },
          transform: state.menuIsOpen ? "rotate(180deg)" : "rotate(0)",
        },
      }),
      valueContainer: (css) => ({
        ...css,
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 10,
        paddingBottom: 10,
        flexGrow: 1,
        flexShrink: 1,
      }),
      dropdownIndicator: (css) => ({
        ...css,
        padding: 0,
      }),
      menu: (css) => ({
        ...css,
        padding: 0,
        marginTop: 16,
        borderRadius: 8,
        boxShadow: "0px 4px 24px 0px rgba(75, 156, 230, 0.12)",
        border: `1px solid ${theme.colors.border.default}`,
        overflow: "hidden",
      }),
      menuList: (css) => ({
        ...css,
        padding: 0,
      }),
      option: (css, state) => ({
        ...css,
        height: 56,
        padding: 16,
        lineHeight: "24px",
        fontSize: 16,
        fontWeight: 600,
        color: state.isSelected ? theme.colors.blue : theme.colors.textPrimary,
        backgroundColor: state.isFocused ? theme.colors.bg.layout : "transparent",
        ":active": {
          backgroundColor: theme.colors.bg.layout,
        },
      }),
      multiValue: (styles, { data }) => ({
        ...styles,
        backgroundColor: theme.colors.secondaryBlue,
      }),
      multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: theme.colors.blue,
      }),
      multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: theme.colors.blue,
        ":hover": {
          backgroundColor: theme.colors.secondaryBlue,
          color: theme.colors.blue,
        },
      }),
    }),
    [theme],
  );

  return customStyles;
};
