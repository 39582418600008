import styled, { css } from "styled-components";

import { device } from "utils/device";
import { linkStyles } from "utils/styles";

import { iconClassName } from "./config";

export const HomePageContainer = styled.main`
  height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;
  gap: 1em;

  @media ${device.tablet} {
    height: calc(100vh - 56px);
  }
`;

export const CardsBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;

  @media ${device.tablet} {
    gap: 16px;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 0 1 calc((100% - 24px) / 2);
  row-gap: 16px;
  padding: 24px;
  border-radius: 16px;
  box-shadow: 0px 12px 56px 0px rgba(109, 162, 223, 0.16);

  ${(p) => css`
    color: ${p.theme.colors.textPrimary};
    border: 1px solid ${p.theme.colors.border.default};
    background-color: ${p.theme.colors.bg.main};
  `}

  @media ${device.tablet} {
    flex: none;
    width: 100%;
  }
`;

export const MapHeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 1.5em;
  align-items: baseline;
  margin-top: 1em;
`;

export const MapTitle = styled.h1``;

export const MapCount = styled.h3`
  color: ${(p) => p.theme.colors.grey};
`;

export const CardIconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: 8px;

  ${(p) => css`
    background-color: ${p.theme.colors.button.primary.bg.default};

    .${iconClassName} {
      path {
        stroke: ${p.theme.colors.white};
      }
    }
  `}
`;

export const CardHeading = styled.h2`
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
`;

export const CardText = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;

export const ShowMoreBtn = styled.button`
  ${linkStyles}
`;

export const SearchBarsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
