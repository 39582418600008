import styled, { css } from "styled-components";
import { device } from "utils/device";

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 24px;
  // flex: 0 1 calc((100% - 24px) / 2);
  padding: 24px;
  /* box-shadow: 0px 12px 56px 0px rgba(109, 162, 223, 0.16); */
  border-radius: 16px;
  /* min-height: 450px; */
  height: 100%;
  min-width: 100%;
  width: 350px;
  overflow: scroll;
  position: relative;

  @media ${device.tablet} {
    /* flex: none; */
    width: 100%;
    height: 100%;
    border-radius: 0px;
    border: none;
    padding: 20px;
    padding-top: 5px;
    /* padding: 0px */
  }

  ${(p) => css`
    background-color: ${p.theme.colors.bg.main};
    border: 1px solid ${p.theme.colors.border.default};
  `}
`;

export const CardInfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  font-size: 14px;
`;

export const CardHeading = styled.h3`
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;
  margin: 0px 0px 10px 0px;
`;

export const CardTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-right: 15px;

  @media ${device.tablet} {
    margin-right: 0;
  }
`;

export const SubTitle = styled.h3`
  font-style: bold;
  font-size: 16px;
`;

export const CardDate = styled.time`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: ${(p) => p.theme.colors.textSecondary};
`;

export const Location = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;

  .location-icon path {
    stroke: ${(p) => p.theme.colors.blue};
  }
`;

export const Address = styled.span`
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;

export const Description = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-clamp: 3;
`;

export const ReqBlock = styled.div``;

export const ReqHeading = styled.h4`
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
  color: ${(p) => p.theme.colors.textSecondary};
`;

export const ReqList = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

export const ReqListItem = styled.li`
  position: relative;
  padding-left: 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;

  ::before {
    content: "";
    display: block;
    position: absolute;
    top: 12px;
    left: 0;
    transform: translateY(-50%);
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: ${(p) => p.theme.colors.blue};
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
