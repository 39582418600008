import styled from "styled-components";

import { device } from "utils/device";

export const MainContainer = styled.div`
  display: flex;
  min-height: 100vh;
  background-color: ${(p) => p.theme.colors.bg.main};
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${(p) => p.theme.colors.bg.layout};
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 40px 40px;
  position: relative;
  height: 100%;
  flex-grow: 1;

  @media ${device.tablet} {
    padding: 0 16px 16px;
  }
`;
