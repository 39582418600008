import { COLORS } from "config/styles/colors";
import styled from "styled-components";
import { ValueProps } from "./types";

export const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
`;

export const Container = styled.div`
  // height: 100vh;
  margin: 2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
`;

export const Slider = styled.div`
  position: relative;
  width: 100%;
`;

export const SliderTrack = styled.div`
  position: absolute;
  border-radius: 3px;
  height: 5px;
  background-color: ${COLORS.strokeGrey};
  width: 100%;
`;

export const SliderRange = styled.div`
  position: absolute;
  border-radius: 3px;
  height: 5px;
  background-color: ${COLORS.blue};
`;

export const SliderLeftValue = styled.div<ValueProps>`
  position: absolute;
  color: ${COLORS.grey};
  font-size: 12px;
  margin-top: 20px;
  // left: 100px;
  left: ${(props) => props.value}%;
`;

export const SliderRightValue = styled.div<ValueProps>`
  position: absolute;
  color: ${COLORS.grey};
  font-size: 12px;
  margin-top: 20px;
  //right: -4px;
  right: ${(props) => 100 - props.value}%;
`;

export const StyledSlider = styled.input`
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  pointer-events: none;
  position: absolute;
  height: 100%;
  width: 100%;
  outline: none;
  ::-webkit-slider-thumb {
    background-color: white;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px ${COLORS.blue};
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }
  ::-moz-range-thumb {
    background-color: white;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px ${COLORS.blue};
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }
  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  }
`;
