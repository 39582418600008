import styled from "styled-components";

export const EmailAddress = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
  text-decoration: none;
  color: inherit;
`;

export const EmailStyle = styled.div`
  display: flex;
  flex-direction: row;

  .message-icon {
    path {
      stroke: ${(p) => p.theme.colors.blue};
    }
  }
`;
